import React from "react";

export const EmptyState = (props) => {
  return (
    <div className="slds-illustration slds-illustration_small">
      <svg
        className="slds-illustration__svg"
        viewBox="0 0 466 297"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-69.000000, -68.000000)">
            <g>
              <g transform="translate(455.000000, 172.000000) scale(-1, 1) translate(-455.000000, -172.000000) translate(377.000000, 153.000000)">
                <polyline
                  vectorEffect="non-scaling-stroke"
                  className="slds-illustration__stroke-secondary"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="26 36 72.5 0 105.5 27"
                ></polyline>
                <polyline
                  vectorEffect="non-scaling-stroke"
                  className="slds-illustration__stroke-secondary"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="96 17 105.5 9 138 36"
                ></polyline>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,36.5 L156,36.5"
                  className="slds-illustration__stroke-secondary"
                  strokeWidth="3"
                  strokeLinecap="round"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M34.202166,22.8278095 L37.207834,33.1133915 L30.8916038,28.4131636 L32.2719085,22.8668 C32.4052855,22.3308624 32.9478721,22.0045226 33.4838096,22.1378996 C33.8288998,22.223781 34.102419,22.486469 34.202166,22.8278095 Z"
                  className="slds-illustration__fill-secondary"
                  transform="translate(34.049719, 27.610728) scale(-1, 1) translate(-34.049719, -27.610728) "
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M28.9755366,18.8278095 L34.0379951,36.1518555 L29.5001232,33.2627202 L24.2782891,29.9851658 L27.0452792,18.8668 C27.1786561,18.3308624 27.7212427,18.0045226 28.2571802,18.1378996 C28.6022704,18.223781 28.8757896,18.486469 28.9755366,18.8278095 Z"
                  className="slds-illustration__fill-secondary"
                  transform="translate(29.158142, 27.129960) scale(-1, 1) translate(-29.158142, -27.129960) "
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M134.935531,21.7547479 L139.204202,37 L130.811328,29.6057303 L133.009603,21.7547479 C133.158515,21.2229175 133.710367,20.9125013 134.242197,21.0614138 C134.57853,21.1555869 134.841358,21.4184152 134.935531,21.7547479 Z"
                  className="slds-illustration__fill-secondary"
                ></path>
              </g>
              <g transform="translate(71.000000, 128.000000)">
                <g>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M237,61.5 L249,61.5"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0,61.5 L230,61.5"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M34.9102011,32.0436939 L38.3925781,48.4296875 L26.453125,58.6445313 L32.9606296,32.0141932 C33.0917307,31.4776944 33.6329274,31.1490548 34.1694262,31.2801559 C34.5424771,31.3713162 34.8303699,31.6680556 34.9102011,32.0436939 Z"
                    className="slds-illustration__fill-secondary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M191.962964,27.4391572 L198,49 L186.770218,39.1063642 L190.037036,27.4391572 C190.185948,26.9073269 190.7378,26.5969106 191.26963,26.7458231 C191.605963,26.8399963 191.868791,27.1028246 191.962964,27.4391572 Z"
                    className="slds-illustration__fill-secondary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M39.9662349,37.3583594 L42.6016149,47.2410342 L40.7070312,48.7349783 L35,48.7349783 L38.0337651,37.3583594 C38.1760682,36.8247225 38.7240258,36.5074839 39.2576627,36.6497871 C39.6036917,36.7420615 39.8739605,37.0123303 39.9662349,37.3583594 Z"
                    className="slds-illustration__fill-secondary"
                  ></path>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="25 61 99.5 0 157.5 45"
                  ></polyline>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="140 30.9433962 157.5 16 212 60"
                  ></polyline>
                </g>
              </g>
              <g
                transform="translate(145.000000, 69.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <g>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M18,27.5 L83.0004985,27.5"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0,27.5 L8,27.5"
                  ></path>
                </g>
              </g>
              <g
                transform="translate(297.000000, 109.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M18,27.5 L83.0004985,27.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,27.5 L8,27.5"
                ></path>
              </g>
              <g transform="translate(113.000000, 75.000000)">
                <g transform="translate(217.000000, 0.000000)">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M20.5,162.789062 L20.5,174.839062"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M20.5,150.789062 L20.5,153.839063"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M20.5,0.772022494 L20.5,111.841169"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    cx="20.5"
                    cy="181"
                    r="6"
                  ></circle>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M20.5,188 C20.5,200 20.5,209.666667 20.5,217 C20.5,228 0.5,228 0.5,217 C0.5,209.666667 0.5,205.282924 0.5,203.848771 L7,211"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M20.5,128 C27.127417,128 32.5,125.627417 32.5,123 C32.5,116.372583 27.127417,111 20.5,111 C13.872583,111 8.5,116.372583 8.5,123 C8.5,126.627417 13.872583,128 20.5,128 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                </g>
                <g
                  transform="translate(0.000000, 134.000000)"
                  className="slds-illustration__stroke-secondary"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M66.5016961,5.72253485 C59.2581641,7.22907384 54.7616802,0 54.7616802,0 L54.7530053,0 C54.7530053,0 51.3235326,5.94519033 44.2795231,5.94519033 C37.5304598,5.94519033 33.8262823,0 33.8262823,0 L33.8147158,0 C33.8147158,0 30.3910264,5.94519033 23.3470169,5.94519033 C16.5950619,5.94519033 12.8937761,0 12.8937761,0 L12.8822096,0 C12.8822096,0 7.08738399,7.34763065 0,5.84109166"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M129.501696,5.72253485 C122.258164,7.22907384 117.76168,0 117.76168,0 L117.753005,0 C117.753005,0 114.323533,5.94519033 107.279523,5.94519033 C100.53046,5.94519033 96.8262823,0 96.8262823,0 L96.8147158,0 C96.8147158,0 93.3910264,5.94519033 86.3470169,5.94519033 C79.5950619,5.94519033 75.8937761,0 75.8937761,0 L75.8822096,0 C75.8822096,0 70.087384,7.34763065 63,5.84109166"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M192.501696,5.72253485 C185.258164,7.22907384 180.76168,0 180.76168,0 L180.753005,0 C180.753005,0 177.323533,5.94519033 170.279523,5.94519033 C163.53046,5.94519033 159.826282,0 159.826282,0 L159.814716,0 C159.814716,0 156.391026,5.94519033 149.347017,5.94519033 C142.595062,5.94519033 138.893776,0 138.893776,0 L138.88221,0 C138.88221,0 133.087384,7.34763065 126,5.84109166"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M259.501696,5.72253485 C252.258164,7.22907384 247.76168,0 247.76168,0 L247.753005,0 C247.753005,0 244.323533,5.94519033 237.279523,5.94519033 C230.53046,5.94519033 226.826282,0 226.826282,0 L226.814716,0 C226.814716,0 223.391026,5.94519033 216.347017,5.94519033 C209.595062,5.94519033 205.893776,0 205.893776,0 L205.88221,0 C205.88221,0 200.087384,7.34763065 193,5.84109166"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M322.501696,5.72253485 C315.258164,7.22907384 310.76168,0 310.76168,0 L310.753005,0 C310.753005,0 307.323533,5.94519033 300.279523,5.94519033 C293.53046,5.94519033 289.826282,0 289.826282,0 L289.814716,0 C289.814716,0 286.391026,5.94519033 279.347017,5.94519033 C272.595062,5.94519033 268.893776,0 268.893776,0 L268.88221,0 C268.88221,0 263.087384,7.34763065 256,5.84109166"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M209.501696,25.7225349 C202.258164,27.2290738 197.76168,20 197.76168,20 L197.753005,20 C197.753005,20 194.323533,25.9451903 187.279523,25.9451903 C180.53046,25.9451903 176.826282,20 176.826282,20 L176.814716,20 C176.814716,20 173.391026,25.9451903 166.347017,25.9451903 C159.595062,25.9451903 155.893776,20 155.893776,20 L155.88221,20 C155.88221,20 150.087384,27.3476307 143,25.8410917"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M405.501696,5.72253485 C398.258164,7.22907384 393.76168,0 393.76168,0 L393.753005,0 C393.753005,0 390.323533,5.94519033 383.279523,5.94519033 C376.53046,5.94519033 372.826282,0 372.826282,0 L372.814716,0 C372.814716,0 369.391026,5.94519033 362.347017,5.94519033 C355.595062,5.94519033 351.893776,0 351.893776,0 L351.88221,0 C351.88221,0 346.087384,7.34763065 339,5.84109166"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M335.501696,25.7225349 C328.258164,27.2290738 323.76168,20 323.76168,20 L323.753005,20 C323.753005,20 320.323533,25.9451903 313.279523,25.9451903 C306.53046,25.9451903 302.826282,20 302.826282,20 L302.814716,20 C302.814716,20 299.391026,25.9451903 292.347017,25.9451903 C285.595062,25.9451903 281.893776,20 281.893776,20 L281.88221,20 C281.88221,20 276.087384,27.3476307 269,25.8410917"
                  ></path>
                </g>
              </g>
              <g transform="translate(373.000000, 263.000000)">
                <g transform="translate(1.000000, 46.000000)" fill="#FFFFFF">
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    points="0.950683594 0.737792969 111.447754 0.737792969 118.248047 5 0.272460938 5 0.272460938 2.88110352"
                  ></polygon>
                </g>
                <g>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    cx="9"
                    cy="28"
                    r="5"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    cx="30"
                    cy="9"
                    r="9"
                  ></circle>
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-secondary"
                    points="0.562988281 51 119.302246 51 123.863281 55 126.056641 62.4726562 114.675781 75.40625 22.7675781 75.40625 13.2597656 70.8320312 7.12890625 65.2988281"
                  ></polygon>
                </g>
                <g transform="translate(0.000000, 25.000000)">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M3.63585436,21.5 L109.778306,21.5 C114.1037,21.5 118.186223,23.4993351 120.838196,26.9163632 L127.5,35.5 L120.800658,45.365147 C118.194742,49.2025017 113.857343,51.5 109.218799,51.5 L31.3324501,51.5 C22.8638438,50.9724099 16.3582376,48.8029983 11.8156315,44.9917652 C7.80246311,41.6247288 4.13067554,35.1206458 0.800268738,25.479516 L0.800270003,25.4795155 C0.259297765,23.9134656 1.09028748,22.2053865 2.65633747,21.6644143 C2.97143994,21.5555661 3.30248145,21.5 3.63585436,21.5 Z"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M142.258276,22.7729944 C138.748717,19.2634357 134.125785,17.4119596 128.242641,17.2426407 C128.41196,23.1257854 130.263436,27.7487171 133.772994,31.2582757 C137.282553,34.7678344 141.905485,36.6193105 147.788629,36.7886294 C147.619311,30.9054847 145.767834,26.282553 142.258276,22.7729944 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(138.015635, 27.015635) scale(-1, 1) translate(-138.015635, -27.015635) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M142.258276,40.7729944 C138.748717,37.2634357 134.125785,35.4119596 128.242641,35.2426407 C128.41196,41.1257854 130.263436,45.7487171 133.772994,49.2582757 C137.282553,52.7678344 141.905485,54.6193105 147.788629,54.7886294 C147.619311,48.9054847 145.767834,44.282553 142.258276,40.7729944 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(138.015635, 45.015635) scale(-1, -1) translate(-138.015635, -45.015635) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M81,-0.442349499 L81,21 C81,22.1045695 80.1045695,23 79,23 L61.5679702,23 C60.4634007,23 59.5679702,22.1045695 59.5679702,21 C59.5679702,20.49722 59.7573376,20.0128843 60.0983633,19.6434398 L79.2651966,-1.1206296 C79.6398003,-1.52645034 80.2724594,-1.5517567 80.6782801,-1.17715294 C80.8833623,-0.987846292 81,-0.721447545 81,-0.442349499 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M50,49.3234743 L50,62 C50,63.1045695 49.1045695,64 48,64 L38.0175677,64 C36.9129982,64 36.0175677,63.1045695 36.0175677,62 C36.0175677,61.4777575 36.2218416,60.9762301 36.5867303,60.602608 L48.2845813,48.6247784 C48.6704604,48.2296635 49.3035811,48.2221765 49.698696,48.6080556 C49.8913701,48.796226 50,49.0541578 50,49.3234743 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(43.000000, 57.000000) scale(1, -1) translate(-43.000000, -57.000000) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M100,49.3234743 L100,62 C100,63.1045695 99.1045695,64 98,64 L88.0175677,64 C86.9129982,64 86.0175677,63.1045695 86.0175677,62 C86.0175677,61.4777575 86.2218416,60.9762301 86.5867303,60.602608 L98.2845813,48.6247784 C98.6704604,48.2296635 99.3035811,48.2221765 99.698696,48.6080556 C99.8913701,48.796226 100,49.0541578 100,49.3234743 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(93.000000, 57.000000) scale(1, -1) translate(-93.000000, -57.000000) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M35,21.1948242 C37.6666667,25.7316081 39,30.6666667 39,36 C39,41.3333333 37.6666667,46.6666667 35,52"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M39,35.5 L126,35.5"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="20"
                    cy="31"
                    r="4"
                  ></circle>
                </g>
              </g>
              <g transform="translate(244.500000, 274.000000) scale(-1, 1) translate(-244.500000, -274.000000) translate(190.000000, 251.000000)">
                <g transform="translate(0.000000, 14.000000)" fill="#FFFFFF">
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    points="91.2460938 8.703125 83.0820312 0.9765625 0.1171875 0.9765625 3.26757813 11.6367188"
                  ></polygon>
                </g>
                <g
                  transform="translate(1.000000, 19.000000)"
                  className="slds-illustration__fill-secondary"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M91.2900009,4 C89.5899629,2.01216046 88.5351253,1.01216046 88.1254883,1 C87.7158512,0.98783954 58.7033187,0.98783954 1.08789063,1 L0.375825695,4 L5.47167969,13.8271484 L20.2802734,18.5742188 C45.4534802,18.6571158 64.3715303,18.8351757 77.0344238,19.1083984 L83.2062537,18.4651753 L88.984375,13.8271484 L91.2900009,4 Z"
                  ></path>
                </g>
                <g>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0.0988502724,15.5 L79.1988702,15.5 C83.8798149,15.5 88.2510535,17.8394159 90.8475744,21.7341973 L94.0247762,26.5 L90.8475744,31.2658027 C88.2510535,35.1605841 83.8798149,37.5 79.1988702,37.5 L20.4692206,37.5 C14.9277254,37.0242117 10.6576403,35.3888654 7.65896553,32.5939611 C4.66029072,29.7990569 2.14025231,24.1010698 0.0988502724,15.5 Z"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M104.678431,16.8215691 C102.1744,14.317538 98.8265543,13.0459654 94.527077,13.027077 C94.5459654,17.3265543 95.817538,20.6743997 98.3215691,23.1784309 C100.8256,25.682462 104.173446,26.9540346 108.472923,26.972923 C108.454035,22.6734457 107.182462,19.3256003 104.678431,16.8215691 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(101.500000, 20.000000) scale(-1, 1) translate(-101.500000, -20.000000) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M104.678431,29.8215691 C102.1744,27.317538 98.8265543,26.0459654 94.527077,26.027077 C94.5459654,30.3265543 95.817538,33.6743997 98.3215691,36.1784309 C100.8256,38.682462 104.173446,39.9540346 108.472923,39.972923 C108.454035,35.6734457 107.182462,32.3256003 104.678431,29.8215691 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(101.500000, 33.000000) scale(-1, -1) translate(-101.500000, -33.000000) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M59,0.234153768 L59,17 L41.0828917,17 L57.2668751,-0.445940303 C57.6424807,-0.850833981 58.2752004,-0.874576673 58.6800941,-0.498971089 C58.8840774,-0.309742913 59,-0.0440844709 59,0.234153768 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M37,35.544829 L37,43.326584 C37,44.4311535 36.1045695,45.326584 35,45.326584 L29.7979244,45.326584 C28.6933549,45.326584 27.7979244,44.4311535 27.7979244,43.326584 C27.7979244,42.8043415 28.0021983,42.3028141 28.367087,41.929192 L35.2845813,34.846133 C35.6704604,34.4510182 36.3035811,34.4435312 36.698696,34.8294103 C36.8913701,35.0175807 37,35.2755125 37,35.544829 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(32.398962, 40.663292) scale(1, -1) translate(-32.398962, -40.663292) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M75,35.544829 L75,43.326584 C75,44.4311535 74.1045695,45.326584 73,45.326584 L67.7979244,45.326584 C66.6933549,45.326584 65.7979244,44.4311535 65.7979244,43.326584 C65.7979244,42.8043415 66.0021983,42.3028141 66.367087,41.929192 L73.2845813,34.846133 C73.6704604,34.4510182 74.3035811,34.4435312 74.698696,34.8294103 C74.8913701,35.0175807 75,35.2755125 75,35.544829 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(70.398962, 40.663292) scale(1, -1) translate(-70.398962, -40.663292) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M24,15 C26,18.8333333 27,22.6666667 27,26.5 C27,30.3333333 26,34.1666667 24,38"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M29,26.5 L92,26.5"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="15.5"
                    cy="22.5"
                    r="2.5"
                  ></circle>
                </g>
              </g>
              <g transform="translate(174.500000, 341.000000) scale(-1, 1) translate(-174.500000, -341.000000) translate(120.000000, 318.000000)">
                <g transform="translate(0.000000, 14.000000)" fill="#FFFFFF">
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    points="91.2460938 8.703125 83.0820312 0.9765625 0.1171875 0.9765625 3.26757813 11.6367188"
                  ></polygon>
                </g>
                <g
                  transform="translate(1.000000, 19.000000)"
                  className="slds-illustration__fill-secondary"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M91.2900009,4 C89.5899629,2.01216046 88.5351253,1.01216046 88.1254883,1 C87.7158512,0.98783954 58.7033187,0.98783954 1.08789063,1 L0.375825695,4 L5.47167969,13.8271484 L20.2802734,18.5742188 C45.4534802,18.6571158 64.3715303,18.8351757 77.0344238,19.1083984 L83.2062537,18.4651753 L88.984375,13.8271484 L91.2900009,4 Z"
                  ></path>
                </g>
                <g>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0.0988502724,15.5 L79.1988702,15.5 C83.8798149,15.5 88.2510535,17.8394159 90.8475744,21.7341973 L94.0247762,26.5 L90.8475744,31.2658027 C88.2510535,35.1605841 83.8798149,37.5 79.1988702,37.5 L20.4692206,37.5 C14.9277254,37.0242117 10.6576403,35.3888654 7.65896553,32.5939611 C4.66029072,29.7990569 2.14025231,24.1010698 0.0988502724,15.5 Z"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M104.678431,16.8215691 C102.1744,14.317538 98.8265543,13.0459654 94.527077,13.027077 C94.5459654,17.3265543 95.817538,20.6743997 98.3215691,23.1784309 C100.8256,25.682462 104.173446,26.9540346 108.472923,26.972923 C108.454035,22.6734457 107.182462,19.3256003 104.678431,16.8215691 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(101.500000, 20.000000) scale(-1, 1) translate(-101.500000, -20.000000) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M104.678431,29.8215691 C102.1744,27.317538 98.8265543,26.0459654 94.527077,26.027077 C94.5459654,30.3265543 95.817538,33.6743997 98.3215691,36.1784309 C100.8256,38.682462 104.173446,39.9540346 108.472923,39.972923 C108.454035,35.6734457 107.182462,32.3256003 104.678431,29.8215691 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(101.500000, 33.000000) scale(-1, -1) translate(-101.500000, -33.000000) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M59,0.234153768 L59,17 L41.0828917,17 L57.2668751,-0.445940303 C57.6424807,-0.850833981 58.2752004,-0.874576673 58.6800941,-0.498971089 C58.8840774,-0.309742913 59,-0.0440844709 59,0.234153768 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M37,35.544829 L37,43.326584 C37,44.4311535 36.1045695,45.326584 35,45.326584 L29.7979244,45.326584 C28.6933549,45.326584 27.7979244,44.4311535 27.7979244,43.326584 C27.7979244,42.8043415 28.0021983,42.3028141 28.367087,41.929192 L35.2845813,34.846133 C35.6704604,34.4510182 36.3035811,34.4435312 36.698696,34.8294103 C36.8913701,35.0175807 37,35.2755125 37,35.544829 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(32.398962, 40.663292) scale(1, -1) translate(-32.398962, -40.663292) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M75,35.544829 L75,43.326584 C75,44.4311535 74.1045695,45.326584 73,45.326584 L67.7979244,45.326584 C66.6933549,45.326584 65.7979244,44.4311535 65.7979244,43.326584 C65.7979244,42.8043415 66.0021983,42.3028141 66.367087,41.929192 L73.2845813,34.846133 C73.6704604,34.4510182 74.3035811,34.4435312 74.698696,34.8294103 C74.8913701,35.0175807 75,35.2755125 75,35.544829 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(70.398962, 40.663292) scale(1, -1) translate(-70.398962, -40.663292) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M24,15 C26,18.8333333 27,22.6666667 27,26.5 C27,30.3333333 26,34.1666667 24,38"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M29,26.5 L92,26.5"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="15.5"
                    cy="22.5"
                    r="2.5"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div className="slds-text-longform">
        <h3 className="slds-text-heading_medium">
          {props.heading && props.heading}
        </h3>
        <p className="slds-text-body_regular">{props.body && props.body}</p>
      </div>
    </div>
  );
};

export default EmptyState;
