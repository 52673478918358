import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { getAllAccounts } from "../../api/accountsAPI";

import { getAccountDetailURL } from "../../utils/navigationUtils";
import { prettifyName, formatAddress } from "../../utils/stringUtils";

export const RecentAccounts = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await getAllAccounts("default", token, 3);
        setAccounts(data);
      } catch (e) {
        setAccounts([]);
        console.error(e);
      }
    })();
  }, []);

  const openRecord = (recordId) => {
    navigate(getAccountDetailURL(recordId));
  };

  return (
    <div>
      {accounts && (
        <div className="slds-m-bottom_x-small">
          <div
            className="slds-page-header"
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <div className="slds-page-header__row">
              <div className="slds-page-header__col-title">
                <div className="slds-media">
                  <div className="slds-media__figure">
                    <span
                      className="slds-icon_container slds-icon-standard-account"
                      title="courses"
                    >
                      <svg
                        className="slds-icon slds-page-header__icon"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#person_account"></use>
                      </svg>
                      <span className="slds-assistive-text">courses</span>
                    </span>
                  </div>
                  <div className="slds-media__body">
                    <div className="slds-page-header__name">
                      <div className="slds-page-header__name-title">
                        <h1>
                          <span
                            className="slds-page-header__title slds-truncate"
                            title="Rohde Corp - 80,000 Widgets"
                          >
                            Clients
                          </span>
                        </h1>
                      </div>
                    </div>
                    <p className="slds-page-header__name-meta">
                      Activité récente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <article
            className="slds-card"
            style={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
          >
            <div className="slds-card__body slds-card__body_inner">
              {accounts.length > 0 && (
                <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                  {accounts.map((account) => (
                    <li
                      className="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-3"
                      key={account._id}
                    >
                      <article className="slds-tile slds-media slds-card__tile slds-hint-parent">
                        <div className="slds-media__figure">
                          <span className="slds-icon_container slds-icon-standard-account">
                            <svg
                              className="slds-icon slds-icon_small"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#person_account"></use>
                            </svg>
                            <span className="slds-assistive-text">Compte</span>
                          </span>
                        </div>
                        <div className="slds-media__body">
                          <div className="slds-grid slds-grid_align-spread slds-has-flexi-truncate">
                            <h3 className="slds-tile__title slds-truncate">
                              <a
                                href="#d"
                                onClick={() => openRecord(account._id)}
                              >
                                {prettifyName(account)}
                              </a>
                            </h3>
                          </div>
                          <div className="slds-tile__detail">
                            <dl className="slds-list_horizontal slds-wrap">
                              <dt className="slds-item_label slds-text-color_weak">
                                Entreprise :
                              </dt>
                              <dd className="slds-item_detail">
                                {account.companyName}
                              </dd>
                              <dt className="slds-item_label slds-text-color_weak">
                                Adresse :
                              </dt>
                              <dd className="slds-item_detail">
                                {formatAddress(account.address)}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </article>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </article>
        </div>
      )}
    </div>
  );
};
export default RecentAccounts;
