import React, { useState } from "react";

import Edit from "../order/EditModal.js";
import Delete from "../order/DeleteModal.js";
import Finalize from "./FinalizeModal.js";
import Paid from "./PaidModal.js";
import Uncollectible from "./UncollectibleModal.js";
import Send from "../order/SendModal.js";
import Void from "../order/VoidModal.js";
import Clone from "../order/CloneModal.js";
import Download from "../order/DownloadModal.js";

export const RecordPageHeaderActions = (props) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const hideEditForm = () => {
    setIsEditVisible(false);
  };

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const hideDeleteForm = () => {
    setIsDeleteVisible(false);
  };

  const [isFinalizeVisible, setIsFinalizeVisible] = useState(false);
  const hideFinalizeForm = () => {
    setIsFinalizeVisible(false);
  };

  const [isSendInvoiceVisible, setIsSendInvoiceVisible] = useState(false);
  const hideSendInvoiceForm = () => {
    setIsSendInvoiceVisible(false);
  };

  const [isMoreActionsVisible, setIsMoreActionsVisible] = useState(false);
  const invertIsMoreActionsVisible = () => {
    setIsMoreActionsVisible(!isMoreActionsVisible);
  };

  const [isPaidInvoiceVisible, setIsPaidInvoiceVisible] = useState(false);
  const hidePaidInvoiceForm = () => {
    setIsPaidInvoiceVisible(false);
  };

  const [isUncollectibleInvoiceVisible, setIsUncollectibleInvoiceVisible] =
    useState(false);
  const hideUncollectibleInvoiceForm = () => {
    setIsUncollectibleInvoiceVisible(false);
  };

  const [isVoidInvoiceVisible, setIsVoidInvoiceVisible] = useState(false);
  const hideVoidInvoiceForm = () => {
    setIsVoidInvoiceVisible(false);
  };

  const [isCloneInvoiceVisible, setIsCloneInvoiceVisible] = useState(false);
  const hideCloneInvoiceForm = () => {
    setIsCloneInvoiceVisible(false);
  };

  const [isDownloadInvoiceVisible, setIsDownloadInvoiceVisible] =
    useState(false);
  const hideDownloadInvoiceForm = () => {
    setIsDownloadInvoiceVisible(false);
  };

  return (
    <div className="slds-page-header__col-actions">
      <div className="slds-page-header__controls">
        <div className="slds-page-header__control">
          <ul className="slds-button-group-list">
            {props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral"
                  onClick={() => setIsDownloadInvoiceVisible(true)}
                >
                  <svg
                    class="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#download"></use>
                  </svg>
                  Télécharger
                </button>
              </li>
            )}
            {props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral slds-show_large"
                  onClick={() => setIsSendInvoiceVisible(true)}
                >
                  <svg
                    class="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#email"></use>
                  </svg>
                  Envoyer
                </button>
              </li>
            )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus !== "draft" &&
              props.order.orderStatus !== "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsCloneInvoiceVisible(true)}
                  >
                    <svg
                      class="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#copy"></use>
                    </svg>
                    Dupliquer
                  </button>
                </li>
              )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsPaidInvoiceVisible(true)}
                  >
                    <svg
                      class="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#success"></use>
                    </svg>
                    Payée
                  </button>
                </li>
              )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsUncollectibleInvoiceVisible(true)}
                  >
                    <svg
                      class="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#thunder"></use>
                    </svg>
                    Impayée
                  </button>
                </li>
              )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsVoidInvoiceVisible(true)}
                  >
                    <svg
                      class="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#ban"></use>
                    </svg>
                    Annuler
                  </button>
                </li>
              )}
            {!props.order.stripeInvoiceId &&
              props.order.totalAmountWithTax > 10 && (
                <li>
                  <button
                    className="slds-button slds-button_neutral"
                    onClick={() => setIsFinalizeVisible(true)}
                  >
                    <svg
                      class="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#lock"></use>
                    </svg>
                    Finaliser
                  </button>
                </li>
              )}
            {!props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral slds-show_large"
                  onClick={() => setIsEditVisible(true)}
                >
                  <svg
                    class="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                  </svg>
                  Modifier
                </button>
              </li>
            )}
            {!props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral slds-show_large"
                  onClick={() => setIsDeleteVisible(true)}
                >
                  <svg
                    class="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                  </svg>
                  Supprimer
                </button>
              </li>
            )}
            <li className="slds-hide_large">
              <div
                className={`slds-dropdown-trigger slds-dropdown-trigger_click ${
                  isMoreActionsVisible ? "slds-is-open" : ""
                }`}
                onClick={invertIsMoreActionsVisible}
              >
                <button
                  className="slds-button slds-button_icon slds-button_icon-border-filled"
                  aria-haspopup="true"
                  title="More Actions"
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#down"></use>
                  </svg>
                  <span className="slds-assistive-text">More Actions</span>
                </button>
                <div
                  className="slds-dropdown slds-dropdown_right slds-dropdown_actions"
                  style={{ zIndex: 999 }}
                >
                  <ul
                    className="slds-dropdown__list"
                    role="menu"
                    aria-label="Show More"
                  >
                    {props.order.stripeInvoiceId && (
                      <li className="slds-dropdown__item" role="presentation">
                        <a href="#maps" role="menuitem" tabIndex="2">
                          <span
                            className="slds-truncate"
                            title="Envoyer"
                            onClick={() => setIsSendInvoiceVisible(true)}
                          >
                            <svg
                              class="slds-button__icon slds-button__icon_left"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#email"></use>
                            </svg>
                            Envoyer
                          </span>
                        </a>
                      </li>
                    )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus !== "draft" &&
                      props.order.orderStatus !== "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#maps" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Dupliquer"
                              onClick={() => setIsCloneInvoiceVisible(true)}
                            >
                              <svg
                                class="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#copy"></use>
                              </svg>
                              Dupliquer
                            </span>
                          </a>
                        </li>
                      )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus === "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#maps" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Payée"
                              onClick={() => setIsPaidInvoiceVisible(true)}
                            >
                              <svg
                                class="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#success"></use>
                              </svg>
                              Payée
                            </span>
                          </a>
                        </li>
                      )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus === "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#maps" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Impayée"
                              onClick={() =>
                                setIsUncollectibleInvoiceVisible(true)
                              }
                            >
                              <svg
                                class="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#thunder"></use>
                              </svg>
                              Impayée
                            </span>
                          </a>
                        </li>
                      )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus === "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#maps" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Annuler"
                              onClick={() => setIsVoidInvoiceVisible(true)}
                            >
                              <svg
                                class="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#ban"></use>
                              </svg>
                              Annuler
                            </span>
                          </a>
                        </li>
                      )}
                    {!props.order.stripeInvoiceId && (
                      <li className="slds-dropdown__item" role="presentation">
                        <a href="#maps" role="menuitem" tabIndex="2">
                          <span
                            className="slds-truncate"
                            title="Modifier"
                            onClick={() => setIsEditVisible(true)}
                          >
                            <svg
                              class="slds-button__icon slds-button__icon_left"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                            </svg>
                            Modifier
                          </span>
                        </a>
                      </li>
                    )}
                    {!props.order.stripeInvoiceId && (
                      <li className="slds-dropdown__item" role="presentation">
                        <a href="#maps" role="menuitem" tabIndex="2">
                          <span
                            className="slds-truncate"
                            title="Supprimer"
                            onClick={() => setIsDeleteVisible(true)}
                          >
                            <svg
                              class="slds-button__icon slds-button__icon_left"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                            </svg>
                            Supprimer
                          </span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {isEditVisible && (
        <Edit
          order={props.order}
          onClose={hideEditForm}
          refetchData={props.refetchData}
        />
      )}
      {isDeleteVisible && (
        <Delete order={props.order} onClose={hideDeleteForm} />
      )}
      {isFinalizeVisible && (
        <Finalize
          order={props.order}
          onClose={hideFinalizeForm}
          refetchData={props.refetchData}
        />
      )}
      {isSendInvoiceVisible && (
        <Send
          order={props.order}
          onClose={hideSendInvoiceForm}
          refetchData={props.refetchData}
        />
      )}
      {isPaidInvoiceVisible && (
        <Paid
          order={props.order}
          onClose={hidePaidInvoiceForm}
          refetchData={props.refetchData}
        />
      )}
      {isUncollectibleInvoiceVisible && (
        <Uncollectible
          order={props.order}
          onClose={hideUncollectibleInvoiceForm}
          refetchData={props.refetchData}
        />
      )}
      {isVoidInvoiceVisible && (
        <Void
          order={props.order}
          onClose={hideVoidInvoiceForm}
          refetchData={props.refetchData}
        />
      )}
      {isCloneInvoiceVisible && (
        <Clone
          order={props.order}
          onClose={hideCloneInvoiceForm}
          refetchData={props.refetchData}
        />
      )}
      {isDownloadInvoiceVisible && (
        <Download
          order={props.order}
          onClose={hideDownloadInvoiceForm}
          refetchData={props.refetchData}
        />
      )}
    </div>
  );
};

export default RecordPageHeaderActions;
