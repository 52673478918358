import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

import { updateOrder } from "../../api/ordersAPI";

import { Loader } from "../shared/Loader";
import { Datepicker } from "../shared/Datepicker";

export const Edit = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();

  const [orderState, setOrderState] = useState(props.order);
  const [orderChanges, setOrderChanges] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update orderState with the new value
    setOrderState((prevOrderState) => ({
      ...prevOrderState,
      [name]: value,
    }));

    // Track changes in orderChanges
    setOrderChanges((prevChanges) => ({
      ...prevChanges,
      [name]: value,
    }));
  };

  const setOrderDate = (date) => {
    if (orderState.orderDate !== date) {
      setOrderChanges((prevState) => ({
        ...prevState,
        orderDate: date,
      }));
    }
  };

  const setOrderDueDate = (date) => {
    if (orderState.orderDueDate !== date) {
      setOrderChanges((prevState) => ({
        ...prevState,
        orderDueDate: date,
      }));
    }
  };

  const handleSave = async () => {
    if (Object.keys(orderChanges).length === 0) {
      props.onClose && props.onClose();
      return;
    }
    if (isButtonDisabled) {
      return;
    } else {
      setIsButtonDisabled(true);
    }
    try {
      const token = await getAccessTokenSilently();
      await updateOrder(id, orderChanges, token);
    } catch (e) {
      console.error(e);
    } finally {
      props.refetchData && props.refetchData();
      setIsButtonDisabled(false);
      props.onClose && props.onClose();
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <div>
      {isButtonDisabled && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler la modification et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              {"Modifier " + props.order.orderReference}
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <section>
              <div className="slds-p-left_x-small">
                <Datepicker
                  label="Date d'émission"
                  onSet={setOrderDate}
                  selectedValue={props.order.orderDate}
                />
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <Datepicker
                  label="Date d'échéance"
                  onSet={setOrderDueDate}
                  selectedValue={props.order.orderDueDate}
                />
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField1Label"
                  >
                    Label en-tête #1
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField1Label"
                      id="text-input-invoiceHeaderField1Label"
                      className="slds-input"
                      placeholder="ex: Référence interne"
                      value={orderState.invoiceHeaderField1Label}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField1Value"
                  >
                    Valeur en-tête #1
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField1Value"
                      id="text-input-invoiceHeaderField1Value"
                      className="slds-input"
                      placeholder="ex: AEZ-3823-BD"
                      value={orderState.invoiceHeaderField1Value}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField2Label"
                  >
                    Label en-tête #2
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField2Label"
                      id="text-input-invoiceHeaderField2Label"
                      className="slds-input"
                      placeholder="ex: Numéro client"
                      value={orderState.invoiceHeaderField2Label}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField2Value"
                  >
                    Valeur en-tête #2
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField2Value"
                      id="text-input-invoiceHeaderField2Value"
                      className="slds-input"
                      placeholder="ex: CUST2424490"
                      value={orderState.invoiceHeaderField2Value}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField3Label"
                  >
                    Label en-tête #3
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField3Label"
                      id="text-input-invoiceHeaderField3Label"
                      className="slds-input"
                      placeholder="ex: IBAN"
                      value={orderState.invoiceHeaderField3Label}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField3Value"
                  >
                    Valeur en-tête #3
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField3Value"
                      id="text-input-invoiceHeaderField3Value"
                      className="slds-input"
                      placeholder="FR760854750842702872804"
                      value={orderState.invoiceHeaderField3Value}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-footer"
                  >
                    Pied de page
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="footer"
                      id="text-input-footer"
                      className="slds-input"
                      placeholder="Merci de votre commande !"
                      value={orderState.footer}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div>
                <div className="slds-p-left_x-small">
                  <label
                    className="slds-form-element__label"
                    htmlFor="textarea-id-specialInstructions"
                  >
                    Demande Spéciale
                  </label>
                  <div className="slds-form-element__control">
                    <textarea
                      id="textarea-id-specialInstructions"
                      className="slds-textarea"
                      rows="5"
                      cols="50"
                      maxLength="255"
                      name="specialInstructions"
                      value={orderState.specialInstructions}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={handleClose}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#back"></use>
              </svg>
              Annuler
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleSave}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#save"></use>
              </svg>
              Enregistrer
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default Edit;
