import React from "react";

export const Header = () => {
  return (
    <div class="slds-page-header slds-m-bottom_x-small">
      <div class="slds-page-header__row">
        <div class="slds-page-header__col-title">
          <div class="slds-media">
            <div class="slds-media__figure">
              <span
                class="slds-icon_container slds-icon-standard-question-feed"
                title="Aide"
              >
                <svg
                  class="slds-icon slds-page-header__icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#question_feed"></use>
                </svg>
                <span class="slds-assistive-text">Aide</span>
              </span>
            </div>
            <div class="slds-media__body">
              <div class="slds-page-header__name">
                <div class="slds-page-header__name-title">
                  <h1>
                    <span
                      class="slds-page-header__title slds-truncate"
                      title="Aide"
                    >
                      Aide
                    </span>
                  </h1>
                </div>
              </div>
              <p class="slds-page-header__name-meta">{`Foire aux questions`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;