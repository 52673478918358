import axios from "axios";

const BASE_URL = process.env.REACT_APP_AWS_API_GATEWAY_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Fetch account by ID
export const getAccount = async (accountId, token) => {
  try {
    const response = await axiosInstance.get(`/accounts/${accountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

// Fetch all accounts
export const getAllAccounts = async (listViewId, token, limit = null) => {
  try {
    if (limit === null) {
      limit = 50;
    }
    const response = await axiosInstance.get(
      `/accounts/?ListViewId=${listViewId}&Limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching all accounts:", error);
    throw error;
  }
};

// Create a new account
export const createAccount = async (accountData, token) => {
  try {
    const response = await axiosInstance.post(`/accounts`, accountData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating the account:", error);
    throw error;
  }
};

// Update an existing account
export const updateAccount = async (accountId, updatedData, token) => {
  try {
    const response = await axiosInstance.patch(
      `/accounts/${accountId}`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating the account:", error);
    throw error;
  }
};

// Delete an account by ID
export const deleteAccount = async (accountId, token) => {
  try {
    const response = await axiosInstance.delete(`/accounts/${accountId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting the account:", error);
    throw error;
  }
};

// Fetch account related orders
export const getAccountRelatedOrders = async (accountId, token) => {
  try {
    const response = await axiosInstance.get(`/accounts/${accountId}/orders/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};
