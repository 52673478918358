import React from "react";
import { formatDatetime } from '../../utils/dateUtils';

export const Detail = (props) => {
  return (
    <div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Information du Client
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-firstName"
                >
                  Prénom
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-firstName"
                    readOnly={true}
                    className="slds-input"
                    value={props.account.firstName}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-lastName"
                >
                  Nom
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-lastName"
                    className="slds-input"
                    value={props.account.lastName}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-companyName"
                >
                  Enteprise
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-companyName"
                    readOnly={true}
                    placeholder=""
                    className="slds-input"
                    value={props.account.companyName}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="text-input-vat">
                  Numéro de TVA
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-vat"
                    className="slds-input"
                    value={props.account.vat}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Communication
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="text-input-email">
                  Email
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-email"
                    readOnly={true}
                    className="slds-input"
                    value={props.account.email}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="text-input-phone">
                  Téléphone
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-phone"
                    className="slds-input"
                    value={props.account.phone}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-id-website"
                >
                  Site web
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-website"
                    readOnly={true}
                    className="slds-input"
                    value={props.account.website}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="text-input-fax">
                  Fax
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-fax"
                    className="slds-input"
                    value={props.account.fax}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Adresse
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="text-input-street">
                  Adresse
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-street"
                    readOnly={true}
                    className="slds-input"
                    value={props.account.address.street}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-zipcode"
                >
                  Code postal
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    className="slds-input"
                    id="text-input-zipcode"
                    value={props.account.address.zipcode}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="text-input-city">
                  Ville
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-city"
                    readOnly={true}
                    className="slds-input"
                    value={props.account.address.city}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-country"
                >
                  Pays
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    className="slds-input"
                    id="text-input-country"
                    value={props.account.address.country}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Système
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-createdDate"
                >
                  Date de création
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-createdDate"
                    className="slds-input"
                    value={formatDatetime(props.account.createdDate)}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-lastModifiedDate"
                >
                  Dernière modification
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-lastModifiedDate"
                    className="slds-input"
                    value={formatDatetime(props.account.lastModifiedDate)}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Detail;
