import React from "react";

export const Loader = (props) => {
  return (
    <section
      role="dialog"
      tabindex="-1"
      aria-modal="true"
      aria-labelledby="modal-heading-01"
      style={{ backgroundColor: "#e5e5e5", opacity: 0.6, zIndex: 999999 }}
      className="slds-modal slds-fade-in-open slds-modal_full"
    >
      <div role="status" class="slds-spinner slds-spinner_medium">
        <span class="slds-assistive-text">Chargement</span>
        <div class="slds-spinner__dot-a"></div>
        <div class="slds-spinner__dot-b"></div>
      </div>
    </section>
  );
};

export default Loader;
