import React from "react";

import HorizontalHeader from "./global/HorizontalHeader";
import HorizontalNavigation from "./global/HorizontalNavigation";

const HorizontalHeaderContainer = () => {
  return (
    <header className="slds-global-header_container slds-show_medium">
      <HorizontalHeader />
      <HorizontalNavigation />
    </header>
  );
};

export default HorizontalHeaderContainer;
