import React, { useState, useEffect } from "react";
import { formatDate } from "../../utils/dateUtils";

export const Datepicker = (props) => {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [displayDatePicker, setDisplayDatePicker] = useState(false);
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth());

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const days = Array.from(
    { length: daysInMonth(selectedYear, selectedMonth) },
    (_, index) => index + 1
  );

  const years = Array.from(
    { length: 3 },
    (_, index) => today.getFullYear() + index
  );

  useEffect(() => {
    if (props.selectedValue) {
      setSelectedDate(props.selectedValue);
    } else {
      setSelectedDate(today);
      props.onSet && props.onSet(today);
    }
  }, []);

  const handleDayClick = (day) => {
    const selectedDateObj = new Date(selectedYear, selectedMonth, day);
    props.onSet && props.onSet(selectedDateObj);
    setSelectedDate(selectedDateObj);
    setDisplayDatePicker(false);
  };

  const invertDisplayOption = () => setDisplayDatePicker(!displayDatePicker);

  const changeMonth = (increment) => {
    let newMonth = selectedMonth + increment;
    let newYear = selectedYear;

    if (newMonth > 11) {
      newMonth = 0;
      newYear += 1;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear -= 1;
    }

    setSelectedMonth(newMonth);
    setSelectedYear(newYear);
  };

  const setToToday = () => {
    const aujourdhui = new Date();
    setSelectedMonth(aujourdhui.getMonth());
    setSelectedYear(aujourdhui.getFullYear());
    const selectedDateObj = new Date(aujourdhui.getFullYear(), aujourdhui.getMonth(), aujourdhui.getDate());
    props.onSet && props.onSet(selectedDateObj);
    setSelectedDate(selectedDateObj);
    setDisplayDatePicker(false);
  };

  const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
  const leadingEmptyDays = Array(firstDayOfMonth).fill(null);
  const trailingDaysCount =
    (7 - ((days.length + leadingEmptyDays.length) % 7)) % 7;
  const trailingEmptyDays = Array(trailingDaysCount).fill(null);
  const combinedDays = [...leadingEmptyDays, ...days, ...trailingEmptyDays];

  return (
    <div
      className={`slds-form-element slds-dropdown-trigger slds-dropdown-trigger_click${
        displayDatePicker ? " slds-is-open" : ""
      }`}
    >
      <label
        className="slds-form-element__label"
        htmlFor="date-input-id-default"
      >
        {props.label}
      </label>
      <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
        <input
          type="text"
          id="date-input-id-default"
          className="slds-input"
          value={formatDate(selectedDate)}
          readOnly={true}
          onClick={invertDisplayOption}
          style={{ border: "solid 1px rgb(201, 201, 201)", paddingLeft: ".75rem" }}
        />
        <button
          className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
          title="Select a date"
          onClick={invertDisplayOption}
        >
          <svg className="slds-button__icon" aria-hidden="true">
            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#event"></use>
          </svg>
          <span className="slds-assistive-text">Select a date</span>
        </button>
      </div>
      <div
        aria-hidden="false"
        aria-label="Date picker: June"
        className="slds-datepicker slds-dropdown slds-dropdown_left"
        role="dialog"
      >
        <div className="slds-datepicker__filter slds-grid">
          <div className="slds-datepicker__filter_month slds-grid slds-grid_align-spread slds-grow">
            <div className="slds-align-middle">
              <button
                className="slds-button slds-button_icon slds-button_icon-container"
                title="Previous Month"
                onClick={() => changeMonth(-1)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#left"></use>
                </svg>
                <span className="slds-assistive-text">Previous Month</span>
              </button>
            </div>
            <h2
              aria-atomic="true"
              aria-live="assertive"
              className="slds-align-middle"
              id="defaultPicker-month"
            >
              {months[selectedMonth]}
            </h2>
            <div className="slds-align-middle">
              <button
                className="slds-button slds-button_icon slds-button_icon-container"
                title="Next Month"
                onClick={() => changeMonth(1)}
              >
                <svg className="slds-button__icon" aria-hidden="true">
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#right"></use>
                </svg>
                <span className="slds-assistive-text">Mois prochain</span>
              </button>
            </div>
          </div>
          <div className="slds-shrink-none">
            <label
              className="slds-assistive-text"
              htmlFor="defaultPicker_select"
            >
              Choisir une année
            </label>
            <div className="slds-select_container">
              <select
                className="slds-select"
                id="defaultPicker_select"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(Number(e.target.value));
                }}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <table
          aria-labelledby="defaultPicker-month"
          aria-multiselectable="true"
          className="slds-datepicker__month"
          role="grid"
        >
          <thead>
            <tr id="defaultPicker-weekdays">
              <th id="defaultPicker-Sunday" scope="col">
                <abbr title="Sunday">Dim</abbr>
              </th>
              <th id="defaultPicker-Monday" scope="col">
                <abbr title="Monday">Lun</abbr>
              </th>
              <th id="defaultPicker-Tuesday" scope="col">
                <abbr title="Tuesday">Mar</abbr>
              </th>
              <th id="defaultPicker-Wednesday" scope="col">
                <abbr title="Wednesday">Mer</abbr>
              </th>
              <th id="defaultPicker-Thursday" scope="col">
                <abbr title="Thursday">Jeu</abbr>
              </th>
              <th id="defaultPicker-Friday" scope="col">
                <abbr title="Friday">Ven</abbr>
              </th>
              <th id="defaultPicker-Saturday" scope="col">
                <abbr title="Saturday">Sam</abbr>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array(Math.ceil(combinedDays.length / 7))
              .fill()
              .map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {combinedDays
                    .slice(rowIndex * 7, rowIndex * 7 + 7)
                    .map((day, cellIndex) => (
                      <td
                        key={cellIndex}
                        aria-selected="false"
                        role="gridcell"
                        aria-label={`${day} ${months[selectedMonth]} ${selectedYear}`}
                        className={day ? "" : "de"}
                        tabIndex={day ? "0" : undefined}
                        onClick={day ? () => handleDayClick(day) : undefined}
                      >
                        {day && <span className="slds-day">{day}</span>}
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </table>
        <button
          className="slds-button slds-align_absolute-center slds-text-link"
          onClick={() => {
            setToToday();
            invertDisplayOption();
          }}
        >
          Aujourd'hui
        </button>
      </div>
    </div>
  );
};

export default Datepicker;
