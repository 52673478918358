import moment from "moment";

export function formatDatetime(date) {
  return moment(date).format("DD/MM/YYYY HH:mm");
}

export function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

export function extractTimeFromDate(dateString) {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};