import React, { useState } from "react";
import Detail from "../account/RecordDetail.js";

export const Tabs = (props) => {
  const [tabs] = useState({
    detail: {
      label: "Détail",
      isActive: false,
    },
  });

  return (
    <div className="slds-tabs_medium slds-tabs-mobile slds-var-p-around_small slds-show_medium">
      <ul className="slds-tabs_default__nav" role="tablist">
        <li
          className="slds-tabs_default__item slds-is-active"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#detail"
            role="tab"
            tabIndex="0"
            aria-selected={true}
            aria-controls="tab-default-1"
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-account">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#record"></use>
                </svg>
              </span>
            </span>
            {tabs.detail.label}
          </a>
        </li>
      </ul>
      <div
        id="tab-default-1"
        className="slds-tabs_default__content slds-show"
        role="tabpanel"
        aria-labelledby="tab-default-1__item"
      >
        <Detail account={props.account} />
      </div>
    </div>
  );
};

export default Tabs;
