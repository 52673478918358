import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { deleteOrderLine } from "../../api/ordersAPI";

import { Loader } from "../shared/Loader";

export const Delete = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleDelete = async () => {
    try {
      setIsButtonDisabled(true);
      const token = await getAccessTokenSilently();
      await deleteOrderLine(id, props.orderLine._id, token);
      props.refresh && props.refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setIsButtonDisabled(false);
      props.onClose && props.onClose();
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <div>
      {isButtonDisabled && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler la modification et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Supprimer la ligne de commande
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <div className="slds-align_absolute-center">
              <p>
                Êtes-vous sûr de vouloir supprimer cette ligne de commande ?
              </p>
            </div>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={handleClose}
            >
              <svg
                class="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#back"></use>
              </svg>
              Annuler
            </button>
            <button
              className="slds-button slds-button_destructive"
              onClick={handleDelete}
              disabled={isButtonDisabled}
            >
              <svg
                class="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
              </svg>
              Supprimer
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default Delete;
