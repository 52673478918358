import React from "react";

export const ArticleLine = (props) => {
  return (
    <li class="slds-setup-assistant__item">
      <article class="slds-setup-assistant__step">
        <div class="slds-setup-assistant__step-summary">
          <div class="slds-media">
            <div class="slds-setup-assistant__step-summary-content slds-media__body">
              <h3 class="slds-setup-assistant__step-summary-title slds-text-heading_small">
                {props.title}
              </h3>
              <p dangerouslySetInnerHTML={{ __html: props.body }} />
            </div>
          </div>
        </div>
      </article>
    </li>
  );
};

export default ArticleLine;
