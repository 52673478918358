export function getAccountLabel(fieldName) {
  let account = {
    label: "Client",
    plural: "Clients",
    delete: "Supprimer",
    edit: "Modifier",
    firstName: "Nom",
    lastName: "Prénom",
    name: "Nom du client",
    email: "Email",
    address: "Adresse",
    phone: "Téléphone",
    companyName: "Entreprise",
  };
  return account[fieldName];
}

export function getDefaultAccountState() {
  return {
    firstName: "",
    lastName: "",
    companyName: "",
    vat: "",
    email: "",
    phone: "",
    website: "",
    fax: "",
    address: {
      street: "",
      zipcode: "",
      city: "",
      country: "",
    },
  };
}

export function getDefaultOrderState() {
  return {
    accountId: "",
    orderDate: "",
    orderDueDate: "",
    invoiceHeaderField1Label: "",
    invoiceHeaderField1Value: "",
    invoiceHeaderField2Label: "",
    invoiceHeaderField2Value: "",
    invoiceHeaderField3Label: "",
    invoiceHeaderField3Value: "",
    footer: "",
    specialInstructions: "",
  };
}

export function getPaymentMethodLabel(method) {
  return {
    cash: "Espèces",
    wire: "Virement",
    creditcard: "Carte bancaire",
  }[method];
}

export function getLabel(type, value) {
  const optionsProviders = {
    paymentMethod: getPaymentMethodOptions,
    orderStatus: getOrderStatusOptions,
  };
  const options = optionsProviders[type]();
  const option = options.find((opt) => opt.value === value);
  return option ? option.label : undefined;
}

export function getPaymentMethodOptions() {
  return [
    { label: "Carte bancaire", value: "creditCard", default: true },
    { label: "Espèces", value: "cash", default: false },
    { label: "Virement", value: "bankTransfer", default: false },
    { label: "Chèque", value: "cheque", default: false },
  ];
}

export function getOrderStatusOptions() {
  return [
    { label: "Brouillon", value: "draft", default: true },
    {
      label: "En cours",
      value: "open",
      default: false,
    },
    { label: "Annulée", value: "void", default: false },
    { label: "Payée", value: "paid", default: false },
    { label: "Non recouvrable", value: "uncollectible", default: false },
  ];
}
