import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getAccount } from "../../api/accountsAPI";

import AccountDetail from "../account/RecordDetail";

export const Detail = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const [account, setAccount] = useState(props.account || null);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await getAccount(props.accountId, token);
      if (data) {
        setAccount(data);
        props.setAccount && props.setAccount(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!account) {
      fetchData();
    }
  }, [props.accountId]);

  return <div>{account && <AccountDetail account={account} />}</div>;
};

export default Detail;
