import React from "react";

import HeaderActions from "./RecordPageHeaderActions";

import { getAccountLabel } from "../../utils/dataModelUtils";
import { prettifyName, formatAddress } from "../../utils/stringUtils";

export const PageHeader = (props) => {
  return (
    <div className="slds-page-header slds-page-header_record-home slds-m-left_xx-small slds-m-right_xx-small">
      <div className="slds-page-header__row">
        <div className="slds-page-header__col-title">
          <div className="slds-media">
            <div className="slds-media__figure">
              <span className="slds-icon_container slds-icon-standard-account">
                <svg
                  className="slds-icon slds-page-header__icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#person_account"></use>
                </svg>
              </span>
            </div>
            <div className="slds-media__body">
              <div className="slds-page-header__name">
                <div className="slds-page-header__name-title">
                  <h1>
                    <span>{getAccountLabel("label")}</span>
                    <span
                      className="slds-page-header__title"
                      title={prettifyName(props.account)}
                    >
                      {prettifyName(props.account)}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeaderActions
          account={props.account}
          refetchData={props.refetchData}
        />
      </div>
      <div className="slds-page-header__row slds-page-header__row_gutters">
        <div className="slds-page-header__col-details">
          <ul className="slds-page-header__detail-row slds-show_medium">
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap">
                {getAccountLabel("companyName")}
              </div>
              <div className="slds-cell-wrap">{props.account.companyName}</div>
            </li>
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap" title="Adresse">
                {getAccountLabel("address")}
              </div>
              <div className="slds-cell-wrap">
                {formatAddress(props.account.address)}
              </div>
            </li>
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap" title="Email">
                {getAccountLabel("email")}
              </div>
              <div className="slds-truncate">
                <a href={"mailto:" + props.account.email}>
                  {props.account.email}
                </a>
              </div>
            </li>
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap">
                {getAccountLabel("phone")}
              </div>
              <div className="slds-cell-wrap">{props.account.phone}</div>
            </li>
          </ul>
          <ul className="slds-page-header__detail-list slds-hide_medium">
            <li className="slds-page-header__detail-item">
              <div
                className="slds-text-title slds-truncate"
                title={getAccountLabel("companyName")}
              >
                {getAccountLabel("companyName")}
              </div>
              <div title={props.account.companyName}>
                {props.account.companyName}
              </div>
            </li>
            <li className="slds-page-header__detail-item">
              <div
                className="slds-text-title slds-truncate"
                title={getAccountLabel("address")}
              >
                {getAccountLabel("address")}
              </div>
              <div title={formatAddress(props.account.address)}>
                {formatAddress(props.account.address)}
              </div>
            </li>
            <li className="slds-page-header__detail-item">
              <div
                className="slds-text-title slds-truncate"
                title={getAccountLabel("email")}
              >
                {getAccountLabel("email")}
              </div>
              <div title={props.account.email} className="slds-truncate">
                <a href={"mailto:" + props.account.email}>
                  {props.account.email}
                </a>
              </div>
            </li>
            <li className="slds-page-header__detail-item">
              <div
                className="slds-text-title slds-truncate"
                title={getAccountLabel("phone")}
              >
                {getAccountLabel("phone")}
              </div>
              <div title={props.account.phone}>{props.account.phone}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
