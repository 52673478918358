export const getHomeURL = () => `/`;

export const getAccountListURL = () => `/accounts/`;

export const getAccountDetailURL = (record_id) => `/accounts/${record_id}/view/`;

export const getOrderListURL = () => `/orders/`;

export const getOrderDetailURL = (record_id) => `/orders/${record_id}/view/`;

export const getSearchURL = (query) => `/search?q=${query}`;

export const getSupportURL = () => `/support`;