import React from "react";
import { useNavigate } from "react-router-dom";

import { getAccountDetailURL } from '../../utils/navigationUtils';
import { prettifyName, formatAddress } from '../../utils/stringUtils';

export const DataTable = (props) => {
  
  const navigate = useNavigate();
  const openRecord = (recordId) => {
    navigate(getAccountDetailURL(recordId))
  };

  return (
    <table
      className="slds-table slds-table_bordered slds-table_fixed-layout slds-max-medium-table_stacked"
      style={{
        borderRightWidth: 1,
        borderRightStyle: "solid",
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        borderColor: "#C9C9C9",
      }}
    >
      <thead>
        <tr className="slds-line-height_reset">
          <th className="slds-cell-wrap" scope="col">
            <div title="Nom du client">
              Nom du client
            </div>
          </th>
          <th className="slds-cell-wrap" scope="col">
            <div title="Entreprise">
              Entreprise
            </div>
          </th>
          <th className="slds-show_small" scope="col">
            <div className="slds-truncate" title="Adresse">
              Adresse
            </div>
          </th>
          <th className="slds-show_small" scope="col">
            <div className="slds-truncate" title="Email">
              Email
            </div>
          </th>
          <th className="slds-show_small" scope="col">
            <div className="slds-truncate" title="Téléphone">
              Téléphone
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.accounts.map((account) => (
          <tr
            className="slds-hint-parent"
            key={account._id || account.lastName}
          >
            <th data-label="Nom" scope="row" className="slds-cell-wrap">
              <div title={account.lastName}>
                <a href="#view" tabIndex="-1" onClick={() => openRecord(account._id)}>
                  {prettifyName(account)}
                </a>
              </div>
            </th>
            <td data-label="Entreprise" className="slds-cell-wrap">
              <div title={account.companyName}>
                {account.companyName}
              </div>
            </td>
            <td data-label="Adresse" className="slds-cell-wrap slds-show_small">
              <div className="slds-truncate" title={formatAddress(account.address)}>
                {formatAddress(account.address)}
              </div>
            </td>
            <td data-label="Email" className="slds-show_small">
              <div className="slds-truncate" title={account.email}>
                {account.email}
              </div>
            </td>
            <td data-label="Téléphone" className="slds-show_small">
              <div className="slds-truncate" title={account.phone}>
                {account.phone}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default DataTable;
