import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from 'react-helmet';

import { search } from "../api/searchAPI";

import { Loader } from "../components/shared/Loader";
import AccountResults from "../components/search/AccountResults";
import OrderResults from "../components/search/OrderResults";
import OrderLineResults from "../components/search/OrderLineResults";
import EmptyResearch from "../components/shared/EmptyResearch";

export const Search = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [showLoader, setShowLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("q");
    if (searchQuery) {
      setQuery(searchQuery);
      fetchData(searchQuery);
    }
  }, [location]);

  const fetchData = async (searchTerm) => {
    try {
      setShowLoader(true)
      const token = await getAccessTokenSilently();
      const results = await search(searchTerm, token);
      setResults(results);
    } catch (e) {
      console.error(e);
    } finally {
      setShowLoader(false)
    }
  };

  return (
    <div>
      <Helmet>
        <title>{`Kipzco - Résultat pour "${query}"`}</title>
      </Helmet>
      {showLoader && <Loader />}
      <div class="slds-page-header slds-m-bottom_x-small">
        <div class="slds-page-header__row">
          <div class="slds-page-header__col-title">
            <div class="slds-media">
              <div class="slds-media__figure">
                <span
                  class="slds-icon_container slds-icon-standard-search"
                  title="search"
                >
                  <svg
                    class="slds-icon slds-page-header__icon"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#search"></use>
                  </svg>
                  <span class="slds-assistive-text">search</span>
                </span>
              </div>
              <div class="slds-media__body">
                <div class="slds-page-header__name">
                  <div class="slds-page-header__name-title">
                    <h1>
                      <span
                        class="slds-page-header__title slds-truncate"
                        title="Résultat de la recherche"
                      >
                        Résultat de la recherche
                      </span>
                    </h1>
                  </div>
                </div>
                <p class="slds-page-header__name-meta">
                {`Résultat pour "${query}"`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {results &&
        results.accounts &&
        results.orders &&
        results.accounts.length === 0 &&
        results.orders.length === 0 &&
        results.orderLines.length === 0 && (
          <article className="slds-card">
            <div className="slds-card__body">
              <EmptyResearch
                heading={`Aucun résultat pour "${query}"`}
                body="Essayez avec un mot-clé différent"
              />
            </div>
          </article>
        )}
      {results && results.accounts && results.accounts.length > 0 && (
        <AccountResults accounts={results.accounts} />
      )}
      {results && results.orders && results.orders.length > 0 && (
        <OrderResults orders={results.orders} />
      )}
      {results && results.orderLines && results.orderLines.length > 0 && (
        <OrderLineResults orderLines={results.orderLines} />
      )}
    </div>
  );
};

export default Search;
