import React from "react";

export const EmptyOrderSafe = (props) => {
  return (
    <div className="slds-illustration slds-illustration_small">
      <svg
        className="slds-illustration__svg"
        viewBox="0 0 454 212"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-70.000000, -95.000000)">
            <g>
              <g transform="translate(124.500000, 222.000000)">
                <g fill="#FFFFFF">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M18.9209988,1.95433401 L33.259296,51.443436 C33.5666778,52.5043744 32.9557995,53.613617 31.8948612,53.9209988 C31.7139843,53.9734036 31.5266126,54 31.3382972,54 L2.6617028,54 C1.5571333,54 0.661702805,53.1045695 0.661702805,52 C0.661702805,51.8116846 0.688299176,51.6243129 0.74070397,51.443436 L15.0790012,1.95433401 C15.386383,0.893395645 16.4956256,0.282517358 17.556564,0.589899164 C18.2152102,0.780726338 18.7301717,1.29568777 18.9209988,1.95433401 Z"
                  ></path>
                </g>
                <g
                  className="slds-illustration__stroke-secondary"
                  strokeLinecap="round"
                  strokeWidth="3"
                >
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    strokeLinejoin="round"
                    points="17 0.323943662 34 54 -1.81721305e-12 54"
                  ></polygon>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M17,4.6953125 C17,43.0456294 17,62.6471919 17,63.5 C17,62.6471919 17,43.0456294 17,4.6953125 Z"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M17,29.3239437 C22.3333333,35.7851611 25,39.1184944 25,39.3239437 C25,39.1184944 22.3333333,35.7851611 17,29.3239437 Z"
                    strokeLinejoin="round"
                    transform="translate(21.000000, 34.323944) scale(-1, 1) translate(-21.000000, -34.323944) "
                  ></path>
                </g>
              </g>
              <g transform="translate(145.000000, 194.000000)">
                <g transform="translate(1.000000, 0.000000)" fill="#FFFFFF">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M25.6478873,0 L50.879042,84.4273253 C51.1953215,85.4856452 50.5937789,86.5999782 49.535459,86.9162577 C49.3496374,86.9717906 49.1567264,87 48.9627843,87 L2.33299037,87 C1.22842087,87 0.332990367,86.1045695 0.332990367,85 C0.332990367,84.8060578 0.361199757,84.6131469 0.416732643,84.4273253 L25.6478873,0 Z"
                  ></path>
                </g>
                <g
                  className="slds-illustration__stroke-secondary"
                  strokeLinecap="round"
                  strokeWidth="3"
                >
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    strokeLinejoin="round"
                    points="26.5 0 52.5 87 0.5 87"
                  ></polygon>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M26.5,2.58642578 C26.5,61.0261034 26.5,90.9972948 26.5,92.5 C26.5,90.9972948 26.5,61.0261034 26.5,2.58642578 Z"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M15.6478873,42 C22.314554,49.078692 25.6478873,52.7453587 25.6478873,53 C25.6478873,52.7453587 22.314554,49.078692 15.6478873,42 Z"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M27.6478873,68 C36.9812207,57.078692 41.6478873,51.7453587 41.6478873,52 C41.6478873,51.7453587 36.9812207,57.078692 27.6478873,68 Z"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </g>
              <g
                transform="translate(404.500000, 245.000000) scale(-1, 1) translate(-404.500000, -245.000000) translate(348.000000, 226.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              >
                <g>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    points="0 38 47.5 0 80.5 26"
                  ></polyline>
                  <polyline
                    vectorEffect="non-scaling-stroke"
                    points="71 17 80.5 9 113 36"
                  ></polyline>
                </g>
              </g>
              <g transform="translate(72.000000, 262.500000)">
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M153.962142,26.4644491 C151.225735,20.0143094 144.944776,15.5029106 137.633892,15.5029106 C135.619663,15.5029106 133.683612,15.8453541 131.878328,16.4764392 C128.451481,11.1704266 122.567406,7.66985447 115.883789,7.66985447 C109.491267,7.66985447 103.830159,10.8721423 100.350851,15.7935668 C98.9589956,14.968161 97.3423157,14.4956341 95.6177606,14.4956341 C94.1083143,14.4956341 92.6815102,14.8576334 91.4157672,15.5014039 C87.9975328,6.58722215 79.5098304,0.275259875 69.5804557,0.275259875 C60.4632836,0.275259875 52.5615782,5.59684366 48.6837305,13.3681823 C46.3912034,12.266973 43.8314865,11.6515593 41.1312741,11.6515593 C32.4373504,11.6515593 25.1998844,18.0312998 23.6476214,26.4644491 L153.962142,26.4644491 Z"
                  className="slds-illustration__fill-secondary"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M13,25 L143,25"
                  className="slds-illustration__stroke-secondary"
                  strokeWidth="3"
                  strokeLinecap="round"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,25 L450,25"
                  className="slds-illustration__stroke-secondary"
                  strokeWidth="3"
                  strokeLinecap="round"
                ></path>
              </g>
              <g transform="translate(344.000000, 247.000000)">
                <g transform="translate(0.293436, 0.054545)">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M165.428708,41.9454545 L0.0995432562,41.9454545 C0.0336614956,41.2089487 0,40.4630069 0,39.7090909 C0,26.2132599 10.7866531,15.2727273 24.0926641,15.2727273 C27.7492016,15.2727273 31.215485,16.0989227 34.3199502,17.5772977 C39.5712028,7.14424616 50.271428,0 62.6175975,0 C76.0636257,0 87.5573893,8.47383452 92.1862485,20.441159 C93.9002755,19.5768947 95.8324059,19.0909091 97.8764479,19.0909091 C100.211783,19.0909091 102.401037,19.7252784 104.285841,20.8333889 C108.997403,14.2263569 116.663488,9.92727273 125.320028,9.92727273 C138.043441,9.92727273 148.627152,19.2146805 150.834755,31.4671412 C151.487388,31.3631046 152.156394,31.3090909 152.837838,31.3090909 C159.117096,31.3090909 164.340238,35.8953699 165.428708,41.9454545 Z"
                    className="slds-illustration__fill-secondary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M32.7065637,40.4454545 L173.706564,40.4454545"
                    className="slds-illustration__stroke-secondary"
                    strokeWidth="3"
                    strokeLinecap="round"
                  ></path>
                </g>
              </g>
              <g transform="translate(105.000000, 203.000000)">
                <g transform="translate(106.000000, 0.000000)" fill="#FFFFFF">
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    points="121.5 48.5 158.5 48.5 158.5 34.5 47.5 34.5 47.5 48.5 93.5 48.5 93.5 69.5 121.5 69.5 121.5 48.5"
                  ></polygon>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M33.9882812,0.21875 C36.5611979,0.21875 70.6126302,0.21875 136.142578,0.21875 L152.384766,11.1132813 C155.083088,16.811292 155.656656,19.677503 154.105469,19.7119141 C152.554281,19.7463252 116.293865,17.6717809 45.3242187,13.4882812 C35.1940104,4.64192708 31.4153646,0.21875 33.9882812,0.21875 Z"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M32.6708984,2.02246094 L21.5554199,0.374195518 L17.6036034,0.374195518 L5.77148437,7.90429688 C3.09089817,12.6737672 3.09089817,15.2284547 5.77148437,15.5683594 C8.45207058,15.9082641 16.1278518,14.3268839 28.7988281,10.8242188 L42.9921875,7.90429688 L41.0699892,5.68448183 L32.6708984,2.02246094 Z"
                  ></path>
                  <rect x="0" y="34" width="48" height="14"></rect>
                </g>
                <g
                  transform="translate(106.000000, 5.000000)"
                  className="slds-illustration__fill-secondary"
                >
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    points="93.3109375 43.4566406 93.3109375 64.6722656 120.925 64.6722656 121.823047 44.1324219 158.5 43.4566406 158.5 97.5 48.5 97.5 48.5 43.6933594"
                  ></polygon>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M132.670898,7.66300119e-19 C125.172201,-2.55433373e-19 94.1907552,-2.55433373e-19 39.7265625,7.66300119e-19 L31.8183594,12.5058594 L29.7050781,28.2714844 L157.78125,28.2714844 L157.78125,15.4775391 C148.539714,5.15917969 140.169596,1.78803361e-18 132.670898,7.66300119e-19 Z"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M37.8266602,0 C34.4632704,0 29.4181858,0 22.6914062,0 C16.1624349,0 9.53808594,3.83528646 2.81835938,11.5058594 L0.705078125,30.2714844 L48.4101562,30.2714844 L48.4101562,14.4775391 L48.1789909,12.3275853 C43.283405,4.10919509 39.832628,0 37.8266602,0 Z"
                  ></path>
                  <rect x="0.5" y="43.5" width="48" height="54"></rect>
                </g>
                <g>
                  <rect
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    x="154.5"
                    y="34.5"
                    width="110"
                    height="68"
                  ></rect>
                  <polygon
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    points="264.5 48.5 264.5 34.5 154.5 34.5 154.5 48.5 199.5 48.5 199.5 69.5 227.5 69.5 227.5 48.5"
                  ></polygon>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M130.5,0.5 L234.5,0.5 C251.068542,0.5 264.5,13.9314575 264.5,30.5 L264.5,34.5 L106.5,34.5 L106.5,24.5 C106.5,11.245166 117.245166,0.5 130.5,0.5 Z"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M130.5,0.5 L130.5,0.5 C143.754834,0.5 154.5,11.245166 154.5,24.5 L154.5,34.5 L106.5,34.5 L106.5,24.5 C106.5,11.245166 117.245166,0.5 130.5,0.5 Z"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <rect
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    x="106.5"
                    y="48.5"
                    width="48"
                    height="54"
                  ></rect>
                  <rect
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    x="106.5"
                    y="34.5"
                    width="48"
                    height="14"
                  ></rect>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M219,52 C219,54.765 216.765,57 214,57 C211.235,57 209,54.765 209,52 C209,49.235 211.235,47 214,47 C216.765,47 219,49.235 219,52 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M214,55 L214,60"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="4"
                    strokeLinecap="round"
                  ></path>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="164"
                    cy="58"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="164"
                    cy="93"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="255"
                    cy="58"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="255"
                    cy="93"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="145"
                    cy="58"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="145"
                    cy="93"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="116"
                    cy="58"
                    r="3"
                  ></circle>
                  <circle
                    vectorEffect="non-scaling-stroke"
                    className="slds-illustration__fill-primary"
                    cx="116"
                    cy="93"
                    r="3"
                  ></circle>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M289.928751,82.2971422 L298,102.518658 L280,102.518658 L288.071249,82.2971422 C288.275982,81.784207 288.857768,81.5343604 289.370703,81.7390942 C289.625359,81.8407378 289.827108,82.0424867 289.928751,82.2971422 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M300.428751,89.8132712 L305.5,102.518658 L293.5,102.518658 L298.571249,89.8132712 C298.775982,89.300336 299.357768,89.0504894 299.870703,89.2552232 C300.125359,89.3568668 300.327108,89.5586158 300.428751,89.8132712 Z"
                    className="slds-illustration__fill-primary"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M93.4287513,82.2971422 L101.5,102.518658 L83.5,102.518658 L91.5712487,82.2971422 C91.7759825,81.784207 92.3577681,81.5343604 92.8707033,81.7390942 C93.1253588,81.8407378 93.3271077,82.0424867 93.4287513,82.2971422 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(92.500000, 92.517446) scale(-1, 1) translate(-92.500000, -92.517446) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M76.9287513,89.8132712 L82,102.518658 L70,102.518658 L75.0712487,89.8132712 C75.2759825,89.300336 75.8577681,89.0504894 76.3707033,89.2552232 C76.6253588,89.3568668 76.8271077,89.5586158 76.9287513,89.8132712 Z"
                    className="slds-illustration__fill-primary"
                    transform="translate(76.000000, 96.275510) scale(-1, 1) translate(-76.000000, -96.275510) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M360,102.5 L372,102.5"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0,102.5 L350,102.5"
                    className="slds-illustration__stroke-primary"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </g>
              </g>
              <g
                transform="translate(150.000000, 96.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M18,27.5 L83.0004985,27.5"
                ></path>
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M0,27.5 L8,27.5"
                ></path>
              </g>
              <g
                transform="translate(271.000000, 135.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <g>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M44,17.5 L63,17.5 C62.2789714,12.0723971 64.081543,7.53186978 68.4077148,3.87841797 C73.3754883,-0.195556641 79.2734375,0.717773438 82.440918,2.12353516 C85.6083984,3.52929687 87.9606934,5.46069336 89.5913086,9.10524041 C90.2822266,10.6397351 90.7517904,11.9379883 91,13"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M83,20.5 C84.0558268,16.8461914 86.2227376,14.4572754 89.5007324,13.333252 C94.4177246,11.6472168 99.0800781,13.8925781 100.942383,16.1518555 C102.804687,18.4111328 103.39502,20.2260742 103.746582,22.1201172 C103.980957,23.3828125 104.06543,24.8427734 104,26.5 C108.141764,26.3313802 110.918945,27.1647135 112.331543,29 C114.040039,31.1936035 114.215332,33.817627 113.593018,35.75 C112.970703,37.682373 110.894531,40.5 107,40.5 L28,40.5"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M18,27.5 L83.0004985,27.5"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0,27.5 L8,27.5"
                  ></path>
                </g>
              </g>
              <g
                transform="translate(402.000000, 164.000000)"
                className="slds-illustration__stroke-secondary"
                strokeLinecap="round"
                strokeWidth="3"
              >
                <g transform="translate(31.713442, 25.088326) rotate(-15.000000) translate(-31.713442, -25.088326) translate(4.713442, 6.588326)">
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M31.0360707,3.43528591 C31.0360707,3.43528591 40.5802283,0.671893051 42.6488424,10.6908663"
                    transform="translate(36.842457, 6.888440) rotate(41.000000) translate(-36.842457, -6.888440) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M40.4282002,10.1797377 C40.4282002,10.1797377 49.9723578,7.4163448 52.0409719,17.435318"
                    transform="translate(46.234586, 13.632892) scale(-1, 1) rotate(-41.000000) translate(-46.234586, -13.632892) "
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M0.730284783,29.5865514 C0.730284783,29.5865514 10.2744424,26.8231586 12.3430565,36.8421318"
                  ></path>
                  <path
                    vectorEffect="non-scaling-stroke"
                    d="M12.7302848,29.5865514 C12.7302848,29.5865514 22.2744424,26.8231586 24.3430565,36.8421318"
                    transform="translate(18.536671, 33.039705) scale(-1, 1) translate(-18.536671, -33.039705) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div className="slds-text-longform">
        <h3 className="slds-text-heading_medium">{props.heading}</h3>
      </div>
    </div>
  );
};

export default EmptyOrderSafe;
