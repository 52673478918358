import axios from "axios";

const BASE_URL = process.env.REACT_APP_AWS_API_GATEWAY_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Fetch order by ID
export const getOrder = async (orderId, token) => {
  try {
    const response = await axiosInstance.get(`/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the Order:", error);
    throw error;
  }
};

// Fetch all orders
export const getAllOrders = async (listViewId, token, limit = null) => {
  try {
    if (limit === null) {
      limit = 50;
    }
    const response = await axiosInstance.get(
      `/orders/?ListViewId=${listViewId}&Limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching all orders:", error);
    throw error;
  }
};

// Create a new order
export const createOrder = async (orderData, token) => {
  try {
    const response = await axiosInstance.post(`/orders`, orderData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating the order:", error);
    throw error;
  }
};

// Update an existing order
export const updateOrder = async (orderId, updatedData, token) => {
  try {
    const response = await axiosInstance.patch(
      `/orders/${orderId}`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating the order:", error);
    throw error;
  }
};

// Delete an order by ID
export const deleteOrder = async (orderId, token) => {
  try {
    const response = await axiosInstance.delete(`/orders/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting the order:", error);
    throw error;
  }
};

// Create a new order
export const createOrderLines = async (orderData, token) => {
  try {
    const response = await axiosInstance.post(
      `/orders/${orderData.orderId}/order-lines/`,
      orderData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating the order line:", error);
    throw error;
  }
};

// Delete an order line by ID
export const deleteOrderLine = async (orderId, orderLineId, token) => {
  try {
    const response = await axiosInstance.delete(
      `/orders/${orderId}/order-lines/${orderLineId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting the order line:", error);
    throw error;
  }
};

// Update an existing order
export const updateOrderLine = async (
  orderId,
  orderLineId,
  updatedData,
  token
) => {
  try {
    const response = await axiosInstance.patch(
      `/orders/${orderId}/order-lines/${orderLineId}/`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating the order line:", error);
    throw error;
  }
};

// Get order related order lines
export const getOrderLines = async (orderId, token) => {
  try {
    const response = await axiosInstance.get(
      `/orders/${orderId}/order-lines/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting the order lines:", error);
    throw error;
  }
};

export const cloneOrder = async (orderId, token) => {
  try {
    const response = await axiosInstance.post(
      `/orders/${orderId}/clone`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error cloning the order:", error);
    throw error;
  }
};
