import React, { useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import {
  getStripeCustomerPortalSession,
  getStripeAccountLoginLink,
} from "../../api/stripeAPI";

import { getSearchURL, getSupportURL } from "../../utils/navigationUtils";

import { Loader } from "../shared/Loader";

export const Header = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState(""); // To hold the search input value
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent the default form action
    if (searchQuery.trim() !== "") {
      navigate(getSearchURL(searchQuery)); // Navigate to the search page with the query
      setSearchQuery("");
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isAvatarOpen, setIsAvatarOpen] = useState(false);

  const openStripeAccountPortal = async () => {
    setIsLoaderOpen(true);
    const token = await getAccessTokenSilently();
    const session = await getStripeAccountLoginLink(token);
    if (session && session.url) {
      window.location.replace(session.url);
    } else {
      setIsAvatarOpen(false);
    }
    setIsLoaderOpen(false);
  };

  const openStripeCustomerPortal = async () => {
    setIsLoaderOpen(true);
    const token = await getAccessTokenSilently();
    const session = await getStripeCustomerPortalSession(
      token,
      window.location.href
    );
    if (session && session.url) {
      window.location.replace(session.url);
    } else {
      setIsAvatarOpen(false);
    }
    setIsLoaderOpen(false);
  };

  const { logout } = useAuth0();
  const logoutWithRedirect = () => {
    setIsAvatarOpen(false);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const triggerAvatarOpen = () => {
    setIsAvatarOpen(!isAvatarOpen);
  };

  return (
    <div className="slds-global-header slds-grid slds-grid_align-spread">
      {isLoaderOpen && <Loader />}
      <div className="slds-global-header__item slds-show_medium">
        <div class="slds-global-header__item"></div>
      </div>
      <div className="slds-global-header__item slds-global-header__item_search">
        <div className="slds-form-element">
          <label
            className="slds-form-element__label slds-assistive-text"
            htmlFor="combobox-id-3"
          >
            Search Kipzco
          </label>
          <div className="slds-form-element__control">
            <div
              className="slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click"
              id="primary-search-combobox-id-2"
            >
              <form onSubmit={handleSearchSubmit}>
                <div
                  className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_left slds-global-search__form-element"
                  role="none"
                >
                  <span className="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_left">
                    <svg
                      className="slds-icon slds-icon slds-icon_xx-small slds-icon-text-default"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#search"></use>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="slds-input slds-combobox__input"
                    id="combobox-id-3"
                    aria-autocomplete="list"
                    aria-controls="search-listbox-id-2"
                    aria-expanded="false"
                    aria-haspopup="listbox"
                    autoComplete="off"
                    role="combobox"
                    placeholder="Rechercher dans Kipzco..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    ref={inputRef}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="slds-global-header__item">
        <ul className="slds-global-actions">
          <li class="slds-global-actions__item">
            <div class="slds-dropdown-trigger slds-dropdown-trigger_click">
              <button
                class="slds-button slds-button_icon slds-button_icon slds-button_icon-container slds-button_icon-small slds-global-actions__help slds-global-actions__item-action"
                aria-haspopup="true"
                title="Aide"
                onClick={() => {
                  navigate(getSupportURL());
                  setIsAvatarOpen(false);
                }}
              >
                <svg
                  class="slds-button__icon slds-global-header__icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#question"></use>
                </svg>
                <span class="slds-assistive-text">Aide</span>
              </button>
            </div>
          </li>
          <li className="slds-global-actions__item">
            <div
              className={`slds-dropdown-trigger slds-dropdown-trigger_click ${
                isAvatarOpen ? "slds-is-open" : ""
              }`}
            >
              <button
                className="slds-button slds-global-actions__avatar slds-global-actions__item-action"
                title="person name"
                aria-haspopup="true"
                onClick={triggerAvatarOpen}
              >
                <span className="slds-avatar slds-avatar_circle slds-avatar_medium">
                  <img
                    alt="Person name"
                    src="/images/kipzco_logo_inverted.png"
                  />
                </span>
              </button>
              <div
                class="slds-dropdown slds-dropdown_right slds-nubbin_top-right"
                style={{ top: 40, right: -9 }}
              >
                <ul class="slds-dropdown__list" role="menu">
                  <li class="slds-dropdown__item" role="presentation">
                    <a
                      href="#dashboard"
                      role="menuitem"
                      tabindex="0"
                      onClick={openStripeAccountPortal}
                    >
                      <span
                        class="slds-truncate"
                        title="Tableau de bord Stripe"
                      >
                        <span
                          class="slds-icon_container slds-icon-utility-service_report slds-m-right_x-small"
                          title="Tableau de bord Stripe"
                        >
                          <svg
                            class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#service_report"></use>
                          </svg>
                          <span class="slds-assistive-text">
                            Tableau de bord Stripe
                          </span>
                        </span>
                        Tableau de bord Stripe
                      </span>
                    </a>
                  </li>
                  <li class="slds-dropdown__item" role="presentation">
                    <a
                      href="#subscription"
                      role="menuitem"
                      tabindex="0"
                      onClick={openStripeCustomerPortal}
                    >
                      <span class="slds-truncate" title="Mon abonnement">
                        <span
                          class="slds-icon_container slds-icon-utility-setup slds-m-right_x-small"
                          title="Mon abonnement"
                        >
                          <svg
                            class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#setup"></use>
                          </svg>
                          <span class="slds-assistive-text">
                            Mon abonnement
                          </span>
                        </span>
                        Mon abonnement
                      </span>
                    </a>
                  </li>
                  <li class="slds-dropdown__item" role="presentation">
                    <a
                      href="#logout"
                      role="menuitem"
                      tabindex="0"
                      onClick={() => {
                        logoutWithRedirect();
                      }}
                    >
                      <span class="slds-truncate" title="Se déconnecter">
                        <span
                          class="slds-icon_container slds-icon-utility-logout slds-m-right_x-small"
                          title="Se déconnecter"
                        >
                          <svg
                            class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#logout"></use>
                          </svg>
                          <span class="slds-assistive-text">
                            Se déconnecter
                          </span>
                        </span>
                        Se déconnecter
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
