import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useAuth0 } from "@auth0/auth0-react";

import { getAllOrders } from "../api/ordersAPI";

import ListPageHeader from "../components/order/ListPageHeader.js";
import DataTable from "../components/order/DataTable.js";
import EmptyState from "../components/shared/EmptyState";

export const Orders = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [orders, setOrders] = useState(null);
  const [listViewId, setListViewId] = useState("default");

  const refreshData = (newListViewId) => {
    setListViewId(newListViewId);
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await getAllOrders(listViewId, token);
        setOrders(data);
      } catch (e) {
        setOrders([]);
        console.error(e);
      }
    })();
  }, [listViewId]);

  return (
    <div>
      <Helmet>
        <title>{`Kipzco - Commandes`}</title>
      </Helmet>
      {orders && (
        <ListPageHeader orders={orders} onSelectListView={refreshData} />
      )}
      {orders && orders.length > 0 && <DataTable orders={orders} />}
      {orders && orders.length === 0 && (
        <article
          className="slds-card"
          style={{
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <div className="slds-card__body">
            <EmptyState heading="Aucune commande à afficher." body="Créez une commande depuis une fiche client ou utilisez une vue différente."/>
          </div>
        </article>
      )}
    </div>
  );
};

export default Orders;
