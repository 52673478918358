import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { getAllOrders } from "../../api/ordersAPI";

import { getOrderDetailURL } from "../../utils/navigationUtils";
import { formatDate } from "../../utils/dateUtils";
import { prettifyName } from "../../utils/stringUtils";

import EmptyOrderSafe from "../shared/EmptyOrderSafe";

export const OpenOrders = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [orders, setOrders] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await getAllOrders("openOrdersForHomePage", token, 3);
        setOrders(data);
      } catch (e) {
        setOrders([]);
        console.error(e);
      }
    })();
  }, []);

  const openRecord = (recordId) => {
    navigate(getOrderDetailURL(recordId));
  };

  return (
    <div>
      {orders && (
        <div className="slds-m-bottom_x-small">
          <div
            className="slds-page-header"
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <div className="slds-page-header__row">
              <div className="slds-page-header__col-title">
                <div className="slds-media">
                  <div className="slds-media__figure">
                    <span
                      className="slds-icon_container slds-icon-standard-orders"
                      title="courses"
                    >
                      <svg
                        className="slds-icon slds-page-header__icon"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#orders"></use>
                      </svg>
                      <span className="slds-assistive-text">courses</span>
                    </span>
                  </div>
                  <div className="slds-media__body">
                    <div className="slds-page-header__name">
                      <div className="slds-page-header__name-title">
                        <h1>
                          <span
                            className="slds-page-header__title slds-truncate"
                            title="Rohde Corp - 80,000 Widgets"
                          >
                            Commandes ouvertes
                          </span>
                        </h1>
                      </div>
                    </div>
                    <p className="slds-page-header__name-meta">
                      En attente de paiement
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <article
            className="slds-card"
            style={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
          >
            <div className="slds-card__body slds-card__body_inner">
              {orders.length === 0 && (
                <EmptyOrderSafe heading={"Commandes sécurisées"} />
              )}
              {orders.length > 0 && (
                <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                  {orders.map((order) => (
                    <li
                      className="slds-p-horizontal_small slds-size_1-of-1 slds-medium-size_1-of-3"
                      key={order._id}
                    >
                      <article className="slds-tile slds-media slds-card__tile slds-hint-parent">
                        <div className="slds-media__figure">
                          <span className="slds-icon_container slds-icon-standard-orders">
                            <svg
                              className="slds-icon slds-icon_small"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#orders"></use>
                            </svg>
                            <span className="slds-assistive-text">Compte</span>
                          </span>
                        </div>
                        <div className="slds-media__body">
                          <div className="slds-grid slds-grid_align-spread slds-has-flexi-truncate">
                            <h3 className="slds-tile__title slds-truncate">
                              <a
                                href="#d"
                                onClick={() => openRecord(order._id)}
                              >
                                {order.orderReference}
                              </a>
                            </h3>
                          </div>
                          <div className="slds-tile__detail">
                            <dl className="slds-list_horizontal slds-wrap">
                              <dt className="slds-item_label slds-text-color_weak">
                                Client :
                              </dt>
                              <dd className="slds-item_detail">
                                {`${prettifyName(order?.parentAccount)}${
                                  order.parentAccount?.companyName
                                    ? ` (${order?.parentAccount?.companyName})`
                                    : ""
                                }`}
                              </dd>
                              <dt className="slds-item_label slds-text-color_weak">
                                Date d'émission :
                              </dt>
                              <dd className="slds-item_detail">
                                {formatDate(order.orderDate)}
                              </dd>
                              <dt className="slds-item_label slds-text-color_weak">
                                Montant :
                              </dt>
                              <dd className="slds-item_detail">
                                {`${parseFloat(
                                  order.totalAmountWithTax
                                ).toFixed(2)}€ TTC`}
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </article>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </article>
        </div>
      )}
    </div>
  );
};
export default OpenOrders;
