import axios from "axios";

const BASE_URL = process.env.REACT_APP_AWS_API_GATEWAY_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getStripeCustomerPortalSession = async (token, redirectURL) => {
  try {
    const response = await axiosInstance.get(
      `/stripe/customer-portal/session?redirectURL=${encodeURIComponent(
        redirectURL
      )}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const getStripeAccountLoginLink = async (token) => {
  try {
    const response = await axiosInstance.get(`/stripe/accounts/login_links`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const generateStripeInvoice = async (token, orderId) => {
  try {
    const response = await axiosInstance.post(
      `/stripe/invoices`,
      {
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const getStripeInvoice = async (token, invoiceId) => {
  try {
    const response = await axiosInstance.get(`/stripe/invoices/${invoiceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const sendStripeInvoice = async (token, invoiceId) => {
  try {
    const response = await axiosInstance.post(
      `/stripe/invoices/${invoiceId}/send`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const paidStripeInvoice = async (token, invoiceId) => {
  try {
    const response = await axiosInstance.post(
      `/stripe/invoices/${invoiceId}/paid`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const uncollectibleStripeInvoice = async (token, invoiceId) => {
  try {
    const response = await axiosInstance.post(
      `/stripe/invoices/${invoiceId}/uncollectible`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const voidStripeInvoice = async (token, invoiceId) => {
  try {
    const response = await axiosInstance.post(
      `/stripe/invoices/${invoiceId}/void`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};