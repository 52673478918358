import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";

import { getOrder } from "../api/ordersAPI";
import { getOrderListURL } from "../utils/navigationUtils";

import PageHeader from "../components/order/RecordPageHeader.js";
import RecordTab from "../components/order/RecordTab.js";
import RelatedTabs from "../components/order/RecordRelatedTabs";

export const OrderDetail = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { id } = useParams();

  const [order, setOrder] = useState({});

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await getOrder(id, token);
      setOrder(data);
      if (data === null) {
        navigate(getOrderListURL());
      } else {
        setOrder(data);
      }
    } catch (e) {
      navigate(getOrderListURL());
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div>
      {order._id && (
        <Helmet>
          <title>{`Kipzco - Commande ${order.orderReference}`}</title>
        </Helmet>
      )}
      {order._id && <PageHeader order={order} refetchData={fetchData} />}
      <div className="slds-grid slds-wrap slds-gutters_x-small slds-var-p-around_x-small">
        <div className="slds-col slds-order_2 slds-medium-order_1 slds-large-order_1 slds-size_1-of-1 slds-medium-size_4-of-12 slds-large-size_4-of-12 slds-show_medium">
          {order._id && <RecordTab order={order} />}
        </div>
        <div className="slds-col slds-order_1 slds-medium-order_2 slds-large-order_2 slds-size_1-of-1 slds-medium-size_8-of-12 slds-large-size_8-of-12">
          {order._id && <RelatedTabs order={order} refetchData={fetchData} />}
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
