import React, { useState } from "react";

import { formatDatetime } from "../../utils/dateUtils";
import { formatFromAddressOrderLine } from "../../utils/stringUtils";

import CreateOrderLineMiseADisposition from "../orderline/CreateOrderLineMiseADisposition";
import EditOrderLineMiseADisposition from "../orderline/EditOrderLineMiseADisposition";
import Delete from "../orderline/DeleteOrderLineModal";

export const RelatedOrderLinesMiseADisposition = (props) => {
  const [
    isCreateOrderLineMiseADisposition,
    setIsCreateOrderLineMiseADisposition,
  ] = useState(false);

  const showCreateOrderLineMiseADisposition = () => {
    setIsCreateOrderLineMiseADisposition(true);
  };

  const hideCreateOrderLineMiseADisposition = () => {
    setIsCreateOrderLineMiseADisposition(false);
  };

  const refresh = () => {
    props.refetchData && props.refetchData();
  };

  const openMaps = (orderline) => {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" +
        formatFromAddressOrderLine(orderline)
    );
  };

  const [selectedOrderLine, setSelectedOrderLine] = useState(null);

  const [isEditVisible, setIsEditVisible] = useState(false);
  const showEditForm = (orderLine) => {
    setSelectedOrderLine(orderLine);
    setIsEditVisible(true);
  };
  const hideEditForm = () => {
    setIsEditVisible(false);
  };

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const showDeleteForm = (orderLine) => {
    setSelectedOrderLine(orderLine);
    setIsDeleteVisible(true);
  };
  const hideDeleteForm = () => {
    setIsDeleteVisible(false);
  };

  return (
    <article className="slds-card">
      <div className="slds-card__header slds-grid">
        <header className="slds-media slds-media_center slds-has-flexi-truncate">
          <div className="slds-media__body">
            <h2 className="slds-card__header-title">
              <span className="slds-truncate">
                Mises à disposition ({props.orderlines.length})
              </span>
            </h2>
          </div>
          <div className="slds-no-flex">
            {props && props.order && !props.order.stripeInvoiceId && (
              <button
                className="slds-button slds-button_neutral"
                onClick={showCreateOrderLineMiseADisposition}
              >
                <svg
                  class="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#new"></use>
                </svg>
                Ajouter
              </button>
            )}
          </div>
        </header>
      </div>
      <div className="slds-card__body">
        {props.orderlines.length > 0 && (
          <table
            className="slds-table slds-no-row-hover slds-table_bordered slds-table_fixed-layout slds-max-medium-table_stacked"
            role="grid"
          >
            <thead>
              <tr className="slds-line-height_reset">
                <th className="slds-cell-wrap" scope="col">
                  <div title="Départ">Départ</div>
                </th>
                <th className="slds-cell-wrap" scope="col">
                  <div title="Date">Date/Heure</div>
                </th>
                <th className="slds-cell-wrap" scope="col">
                  <div title="Durée">Durée</div>
                </th>
                <th className="slds-cell-wrap" scope="col">
                  <div title="Montant TTC">Montant TTC</div>
                </th>
                <th className="slds-cell-wrap" scope="col">
                  <div title="Statut">Description</div>
                </th>
                <th className="" scope="col" style={{ width: "4rem" }}>
                  <div
                    className="slds-truncate slds-assistive-text"
                    title="Actions"
                  >
                    Actions
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {props.orderlines.map((orderLine) => (
                <tr className="slds-hint-parent" key={orderLine._id}>
                  <th
                    data-label="Départ"
                    scope="row"
                    className="slds-cell-wrap"
                  >
                    <div title={formatFromAddressOrderLine(orderLine)}>
                      <a
                        href="#view"
                        tabIndex="-1"
                        onClick={() => openMaps(orderLine)}
                      >
                        {formatFromAddressOrderLine(orderLine)}
                      </a>
                    </div>
                  </th>
                  <td data-label="Date/Heure" className="slds-cell-wrap">
                    <div title={formatDatetime(orderLine.startDate)}>
                      {formatDatetime(orderLine.startDate)}
                    </div>
                  </td>
                  <td data-label="Durée" className="slds-cell-wrap">
                    <div title={orderLine.numberOfHours}>
                      {orderLine.numberOfHours}h
                    </div>
                  </td>
                  <td data-label="Montant TTC" className="slds-cell-wrap">
                    <div
                      title={parseFloat(orderLine.totalAmountWithTax).toFixed(
                        2
                      )}
                    >
                      {parseFloat(orderLine.totalAmountWithTax).toFixed(2)}€ (
                      {parseFloat(orderLine.hourlyRate).toFixed(2)}€/h)
                    </div>
                  </td>
                  <td data-label="Description" className="slds-cell-wrap">
                    <div
                      title={orderLine.description}
                      className="slds-cell-wrap"
                    >
                      {orderLine.description}
                    </div>
                  </td>
                  <td role="gridcell">
                    {props && props.order && !props.order.stripeInvoiceId && (
                      <button
                        className="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small slds-show_medium"
                        onClick={() => showEditForm(orderLine)}
                      >
                        <svg
                          className="slds-button__icon slds-button__icon_hint slds-button__icon_small"
                          aria-hidden="true"
                        >
                          <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                        </svg>
                      </button>
                    )}
                    {props && props.order && !props.order.stripeInvoiceId && (
                      <button
                        className="slds-button slds-button_icon slds-button_icon-border-filled slds-button_icon-x-small slds-show_medium"
                        onClick={() => showDeleteForm(orderLine)}
                      >
                        <svg
                          className="slds-button__icon slds-button__icon_hint slds-button__icon_small"
                          aria-hidden="true"
                        >
                          <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                        </svg>
                      </button>
                    )}
                    {props && props.order && !props.order.stripeInvoiceId && (
                      <button
                        className="slds-button slds-button_neutral slds-hide_medium"
                        onClick={() => showEditForm(orderLine)}
                      >
                        <svg
                          className="slds-button__icon slds-button__icon_left"
                          aria-hidden="true"
                        >
                          <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                        </svg>
                        Modifier
                      </button>
                    )}
                    {props && props.order && !props.order.stripeInvoiceId && (
                      <button
                        className="slds-button slds-button_neutral slds-hide_medium"
                        onClick={() => showDeleteForm(orderLine)}
                      >
                        <svg
                          className="slds-button__icon slds-button__icon_left"
                          aria-hidden="true"
                        >
                          <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                        </svg>
                        Supprimer
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {isCreateOrderLineMiseADisposition && (
        <CreateOrderLineMiseADisposition
          onClose={hideCreateOrderLineMiseADisposition}
          order={props.order}
          refresh={refresh}
        />
      )}
      {isEditVisible && (
        <EditOrderLineMiseADisposition
          onClose={hideEditForm}
          order={props.order}
          orderLine={selectedOrderLine}
          refresh={refresh}
        />
      )}
      {isDeleteVisible && (
        <Delete
          orderLine={selectedOrderLine}
          onClose={hideDeleteForm}
          refresh={refresh}
        />
      )}
    </article>
  );
};

export default RelatedOrderLinesMiseADisposition;
