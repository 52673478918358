export function getMiseADispositionOrderLineState(orderLine) {
  return {
    orderId: null,
    orderReference: null,
    type: "mise_a_disposition",
    description: orderLine?.description || "",
    startDate: (orderLine?.startDate) ? new Date(orderLine?.startDate) : new Date(),
    numberOfHours: orderLine?.numberOfHours || null,
    hourlyRate: orderLine?.hourlyRate || null,
    fromAddressName: orderLine?.fromAddressName || null,
    fromAddressStreetNumber: orderLine?.fromAddressStreetNumber || null,
    fromAddressRoute: orderLine?.fromAddressRoute || null,
    fromAddressCity: orderLine?.fromAddressCity || null,
    fromAddressZipcode: orderLine?.fromAddressZipcode || null,
    fromAddressCountry: orderLine?.fromAddressCountry || null,
    fromAddressLat: orderLine?.fromAddressLat || null,
    fromAddressLng: orderLine?.fromAddressLng || null,
  };
}

export function getTransfertOrderLineState(orderLine) {
  return {
    type: "transfert",
    orderId: orderLine?.orderId || null,
    orderReference: null,
    description: orderLine?.description || "",
    startDate: (orderLine?.startDate) ? new Date(orderLine?.startDate) : new Date(),
    totalAmountWithTax: orderLine?.totalAmountWithTax || null,
    fromAddressName: orderLine?.fromAddressName || null,
    fromAddressStreetNumber: orderLine?.fromAddressStreetNumber || null,
    fromAddressRoute: orderLine?.fromAddressRoute || null,
    fromAddressCity: orderLine?.fromAddressCity || null,
    fromAddressZipcode: orderLine?.fromAddressZipcode || null,
    fromAddressCountry: orderLine?.fromAddressCountry || null,
    fromAddressLat: orderLine?.fromAddressLat || null,
    fromAddressLng: orderLine?.fromAddressLng || null,
    toAddressName: orderLine?.toAddressName || null,
    toAddressStreetNumber: orderLine?.toAddressStreetNumber || null,
    toAddressRoute: orderLine?.toAddressRoute || null,
    toAddressCity: orderLine?.toAddressCity || null,
    toAddressZipcode: orderLine?.toAddressZipcode || null,
    toAddressCountry: orderLine?.toAddressCountry || null,
    toAddressLat: orderLine?.toAddressLat || null,
    toAddressLng: orderLine?.toAddressLng || null,
  };
}

export function validateMiseADisposition(orderline) {
  return (
    orderline.startDate !== null &&
    orderline.numberOfHours !== null &&
    orderline.numberOfHours !== "" &&
    orderline.hourlyRate !== null &&
    orderline.hourlyRate !== "" &&
    orderline.fromAddressName !== undefined &&
    orderline.fromAddressName !== "" &&
    orderline.fromAddressName !== null
  );
}

export function validateTransfert(orderline) {
  return (
    orderline.startDate !== null &&
    orderline.totalAmountWithTax !== null &&
    orderline.totalAmountWithTax !== "" &&
    orderline.fromAddressName !== undefined &&
    orderline.fromAddressName !== "" &&
    orderline.fromAddressName !== null &&
    orderline.toAddressName !== undefined &&
    orderline.toAddressName !== "" &&
    orderline.toAddressName !== null
  );
}
