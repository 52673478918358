import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getStripeInvoice } from "../../api/stripeAPI";
import { Loader } from "../shared/Loader";

export const DownloadModal = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loader, setLoader] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [hostedInvoiceUrl, setHostedInvoiceUrl] = useState(null);

  useEffect(() => {
    const fetchInvoiceUrl = async () => {
      try {
        const token = await getAccessTokenSilently();
        const invoice = await getStripeInvoice(
          token,
          props.order.stripeInvoiceId
        );
        setInvoiceUrl(invoice.invoice_pdf);
        setHostedInvoiceUrl(invoice.hosted_invoice_url);
        setIsButtonDisabled(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    };

    fetchInvoiceUrl();
  }, [getAccessTokenSilently, props.order.stripeInvoiceId]);

  const handleDownload = () => {
    if (invoiceUrl) {
      const newWindow = window.open(invoiceUrl, "_blank");
      if (newWindow) {
        newWindow.focus();
      }
    }
  };

  const handleOpenHostedUrl = () => {
    if (hostedInvoiceUrl) {
      const newWindow = window.open(hostedInvoiceUrl, "_blank");
      if (newWindow) {
        newWindow.focus();
      }
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <div>
      {loader && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Téléchargement de la facture
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <div className="slds-align_absolute-center">
              <p>
                {!invoiceUrl &&
                  "Veuillez patienter, votre facture est en cours de préparation..."}
                {invoiceUrl &&
                  "Votre facture est prête, vous pouvez à présent la télécharger."}
              </p>
            </div>
          </div>
          <div className="slds-modal__footer slds-modal__footer_directional">
          <button
              className="slds-button slds-button_neutral"
              onClick={handleOpenHostedUrl}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#cart"></use>
              </svg>
              Paiement
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleDownload}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#download"></use>
              </svg>
              Télécharger
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default DownloadModal;
