import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { formatAddress, formatAddressWeather } from "../../utils/stringUtils";

import { updateAccount } from "../../api/accountsAPI";
import { getAccountLabel } from "../../utils/dataModelUtils";

import Edit from "../account/EditModal.js";
import Delete from "../account/DeleteModal.js";

export const RecordPageHeaderActions = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();

  const [isEditVisible, setIsEditVisible] = useState(false);
  const showEditForm = () => {
    setIsEditVisible(true);
  };
  const hideEditForm = () => {
    setIsEditVisible(false);
  };

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const showDeleteForm = () => {
    setIsDeleteVisible(true);
  };
  const hideDeleteForm = () => {
    setIsDeleteVisible(false);
  };

  const [isMoreActionsVisible, setIsMoreActionsVisible] = useState(false);
  const invertIsMoreActionsVisible = () => {
    setIsMoreActionsVisible(!isMoreActionsVisible);
  };

  const [isFavorite, setIsFavorite] = useState(props.account.isFavorite);
  const handleFavorite = async () => {
    try {
      setIsFavorite(!isFavorite);
      const token = await getAccessTokenSilently();
      await updateAccount(id, { isFavorite: !isFavorite }, token);
    } catch (e) {
      setIsFavorite(!isFavorite);
      console.error(e);
    }
  };

  const openMaps = () => {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" +
        formatAddress(props.account.address)
    );
  };

  const openWeather = () => {
    window.open(
      "https://www.google.com/search?q=Météo " +
        formatAddressWeather(props.account.address)
    );
  };

  return (
    <div className="slds-page-header__col-actions">
      <div className="slds-page-header__controls">
        <div className="slds-page-header__control">
          <ul className="slds-button-group-list">
            <li>
              <button
                className={`slds-button ${
                  isFavorite ? "slds-button_brand" : "slds-button_neutral"
                }`}
                onClick={handleFavorite}
              >
                <svg className="slds-button__icon">
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#favorite"></use>
                </svg>
                &nbsp;
              </button>
            </li>
            <li>
              <button
                className="slds-button slds-button_neutral slds-show_medium"
                onClick={openWeather}
              >
                <svg
                  class="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#podcast_webinar"></use>
                </svg>
                Météo
              </button>
            </li>
            <li>
              <button
                className="slds-button slds-button_neutral slds-show_medium"
                onClick={openMaps}
              >
                <svg
                  class="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#checkin"></use>
                </svg>
                Maps
              </button>
            </li>
            <li>
              <button
                className="slds-button slds-button_neutral slds-show_medium"
                onClick={showEditForm}
              >
                <svg
                  class="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                </svg>
                {getAccountLabel("edit")}
              </button>
            </li>
            <li>
              <button
                className="slds-button slds-button_neutral slds-show_medium"
                onClick={showDeleteForm}
              >
                <svg
                  class="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                </svg>
                {getAccountLabel("delete")}
              </button>
            </li>
            <li className="slds-hide_medium">
              <div
                className={`slds-dropdown-trigger slds-dropdown-trigger_click ${
                  isMoreActionsVisible ? "slds-is-open" : ""
                }`}
                onClick={invertIsMoreActionsVisible}
              >
                <button
                  className="slds-button slds-button_icon slds-button_icon-border-filled"
                  aria-haspopup="true"
                  title="More Actions"
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#down"></use>
                  </svg>
                  <span className="slds-assistive-text">More Actions</span>
                </button>
                <div
                  className="slds-dropdown slds-dropdown_right slds-dropdown_actions"
                  style={{ zIndex: 999 }}
                >
                  <ul
                    className="slds-dropdown__list"
                    role="menu"
                    aria-label="Show More"
                  >
                    <li className="slds-dropdown__item" role="presentation">
                      <a href="#meteo" role="menuitem" tabIndex="1">
                        <span
                          className="slds-truncate"
                          title="Météo"
                          onClick={openWeather}
                        >
                          <svg
                            class="slds-button__icon slds-button__icon_left"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#podcast_webinar"></use>
                          </svg>
                          Météo
                        </span>
                      </a>
                    </li>
                    <li className="slds-dropdown__item" role="presentation">
                      <a href="#maps" role="menuitem" tabIndex="2">
                        <span
                          className="slds-truncate"
                          title="Météo"
                          onClick={openMaps}
                        >
                          <svg
                            class="slds-button__icon slds-button__icon_left"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#checkin"></use>
                          </svg>
                          Maps
                        </span>
                      </a>
                    </li>
                    <li className="slds-dropdown__item" role="presentation">
                      <a href="#maps" role="menuitem" tabIndex="2">
                        <span
                          className="slds-truncate"
                          title="Météo"
                          onClick={showEditForm}
                        >
                          <svg
                            class="slds-button__icon slds-button__icon_left"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                          </svg>
                          {getAccountLabel("edit")}
                        </span>
                      </a>
                    </li>
                    <li className="slds-dropdown__item" role="presentation">
                      <a href="#maps" role="menuitem" tabIndex="2">
                        <span
                          className="slds-truncate"
                          title="Météo"
                          onClick={showDeleteForm}
                        >
                          <svg
                            class="slds-button__icon slds-button__icon_left"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                          </svg>
                          {getAccountLabel("delete")}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {isEditVisible && (
        <Edit
          account={props.account}
          onClose={hideEditForm}
          refetchData={props.refetchData}
        />
      )}
      {isDeleteVisible && (
        <Delete account={props.account} onClose={hideDeleteForm} />
      )}
    </div>
  );
};

export default RecordPageHeaderActions;
