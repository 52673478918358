import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getStripeCustomerPortalSession,
  getStripeAccountLoginLink,
} from "../../api/stripeAPI";

import {
  getHomeURL,
  getAccountListURL,
  getOrderListURL,
  getSearchURL,
  getSupportURL,
} from "../../utils/navigationUtils";

import { Loader } from "../shared/Loader";

export const VerticalNavigation = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const triggerMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { logout } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const [searchQuery, setSearchQuery] = useState(""); // To hold the search input value
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent the default form action
    if (searchQuery.trim() !== "") {
      // Ensure there's a non-empty search query
      navigate(getSearchURL(searchQuery)); // Navigate to the search page with the query
      setIsMenuOpen(false); // Close the menu
      setSearchQuery("");
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const openStripeCustomerPortal = async () => {
    setIsLoaderOpen(true);
    const token = await getAccessTokenSilently();
    const session = await getStripeCustomerPortalSession(
      token,
      window.location.href
    );
    if (session && session.url) {
      window.location.replace(session.url);
    } else {
      triggerMenu();
    }
    setIsLoaderOpen(false);
  };

  const openStripeAccountPortal = async () => {
    setIsLoaderOpen(true);
    const token = await getAccessTokenSilently();
    const session = await getStripeAccountLoginLink(token);
    if (session && session.url) {
      window.location.replace(session.url);
    } else {
      triggerMenu();
    }
    setIsLoaderOpen(false);
  };

  return (
    <div style={{ width: "100%", backgroundColor: "#FAFAFB" }}>
      {isLoaderOpen && <Loader />}
      <div className="slds-context-bar__icon-action" style={{ height: 60 }}>
        <button
          className="slds-button slds-icon-waffle_container slds-context-bar__button"
          title="Description of the icon when needed"
          onClick={triggerMenu}
          style={{ marginLeft: 5 }}
        >
          <span className="slds-icon-waffle">
            <span className="slds-r1"></span>
            <span className="slds-r2"></span>
            <span className="slds-r3"></span>
            <span className="slds-r4"></span>
            <span className="slds-r5"></span>
            <span className="slds-r6"></span>
            <span className="slds-r7"></span>
            <span className="slds-r8"></span>
            <span className="slds-r9"></span>
          </span>
          <span className="slds-assistive-text">Navigation</span>
        </button>
        <span className="slds-avatar slds-avatar-mobile">
          <img alt="Person name" src="/images/kipzco_logo_inverted.png" />
        </span>
        <form onSubmit={handleSearchSubmit} className="kipzco-global-search">
          <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left">
            <span className="slds-icon_container slds-icon-utility-search">
              <svg
                className="slds-icon slds-input__icon slds-input__icon_right slds-icon-text-default"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#search"></use>
              </svg>
            </span>
            <input
              type="search"
              id="input-id-search"
              placeholder="Rechercher dans Kipzco..."
              className="slds-input search-mobile-input"
              value={searchQuery}
              onChange={handleSearchChange}
              ref={inputRef}
            />
          </div>
        </form>
      </div>
      <nav
        aria-label="Sub page"
        className={`slds-nav-vertical ${
          isMenuOpen ? "slds-show" : "slds-hide"
        }`}
        style={{
          position: "fixed",
          top: 50,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "#FAFAFB",
        }}
      >
        <div className="slds-nav-vertical__section">
          <h2
            id="entity-header"
            className="slds-nav-vertical__title slds-m-top_small"
          >
            GESTION
          </h2>
          <ul aria-describedby="entity-header">
            <li
              className={`slds-nav-vertical__item ${
                location.pathname === "/" ? "slds-is-active" : ""
              }`}
            >
              <a
                href="#home"
                className="slds-nav-vertical__action"
                aria-current="true"
                onClick={() => {
                  navigate(getHomeURL());
                  triggerMenu();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-home slds-line-height_reset"
                  title="Accueil"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#home"></use>
                  </svg>
                  <span class="slds-assistive-text">Accueil</span>
                </span>
                Accueil
              </a>
            </li>
            <li
              className={`slds-nav-vertical__item ${
                location.pathname.startsWith("/accounts")
                  ? "slds-is-active"
                  : ""
              }`}
            >
              <a
                href="#accounts"
                className="slds-nav-vertical__action"
                onClick={() => {
                  navigate(getAccountListURL());
                  triggerMenu();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-people slds-line-height_reset"
                  title="Clients"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#people"></use>
                  </svg>
                  <span class="slds-assistive-text">Clients</span>
                </span>
                Clients
              </a>
            </li>
            <li
              className={`slds-nav-vertical__item ${
                location.pathname.startsWith("/orders") ? "slds-is-active" : ""
              }`}
            >
              <a
                href="#orders"
                className="slds-nav-vertical__action"
                onClick={() => {
                  navigate(getOrderListURL());
                  triggerMenu();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-orders slds-line-height_reset"
                  title="Commandes"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#orders"></use>
                  </svg>
                  <span class="slds-assistive-text">Commandes</span>
                </span>
                Commandes
              </a>
            </li>
          </ul>
        </div>
        <div className="slds-nav-vertical__section">
          <h2 id="entity-header" className="slds-nav-vertical__title">
            STRIPE
          </h2>
          <ul aria-describedby="entity-header">
            <li className="slds-nav-vertical__item">
              <a
                href="#stripeExpress"
                className="slds-nav-vertical__action"
                onClick={() => {
                  openStripeAccountPortal();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-service_report slds-line-height_reset"
                  title="Tableau de bord"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#service_report"></use>
                  </svg>
                  <span class="slds-assistive-text">Tableau de bord</span>
                </span>
                Tableau de bord
              </a>
            </li>
          </ul>
        </div>
        <div className="slds-nav-vertical__section">
          <h2 id="entity-header" className="slds-nav-vertical__title">
            COMPTE
          </h2>
          <ul aria-describedby="entity-header">
            <li className="slds-nav-vertical__item">
              <a
                href="#subscription"
                className="slds-nav-vertical__action"
                onClick={() => {
                  openStripeCustomerPortal();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-setup slds-line-height_reset"
                  title="Mon abonnement"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#setup"></use>
                  </svg>
                  <span class="slds-assistive-text">Mon abonnement</span>
                </span>
                Mon abonnement
              </a>
            </li>
            <li className="slds-nav-vertical__item">
              <a
                href="#support"
                className="slds-nav-vertical__action"
                onClick={() => {
                  navigate(getSupportURL());
                  triggerMenu();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-help slds-line-height_reset"
                  title="Aide"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#help"></use>
                  </svg>
                  <span class="slds-assistive-text">Aide</span>
                </span>
                Aide
              </a>
            </li>
            <li className="slds-nav-vertical__item">
              <a
                href="#home"
                className="slds-nav-vertical__action"
                aria-current="true"
                onClick={() => {
                  logoutWithRedirect();
                }}
              >
                <span
                  class="slds-icon_container slds-icon-utility-logout slds-line-height_reset"
                  title="Se déconnecter"
                >
                  <svg
                    class="slds-icon slds-icon-text-default slds-icon_x-small slds-m-right_x-small"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#logout"></use>
                  </svg>
                  <span class="slds-assistive-text">Se déconnecter</span>
                </span>
                Se déconnecter
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default VerticalNavigation;
