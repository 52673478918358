import React, { useState } from "react";

import OrderRecordDetail from "../order/RecordDetail";
import AccountDetail from "../order/RecordAccountDetail";

export const RecordTab = (props) => {
  const [tabs, setTabs] = useState({
    detail: {
      label: "Détail",
      isActive: true,
    },
    client: {
      label: "Client",
      isActive: false,
    },
  });

  const openTab = (tabName) => {
    const updatedTabs = { ...tabs };
    for (const tab in updatedTabs) {
      updatedTabs[tab].isActive = tab === tabName;
    }
    setTabs(updatedTabs);
  };

  const [account, setAccount] = useState(null);

  return (
    <div className="slds-tabs_medium slds-tabs-mobile slds-var-p-around_small slds-m-bottom_x-small">
      <ul className="slds-tabs_default__nav" role="tablist">
        <li
          className={`slds-tabs_default__item ${
            tabs.detail.isActive ? "slds-is-active" : ""
          }`}
          title="Détail"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#detail"
            role="tab"
            tabIndex="0"
            aria-selected={tabs.detail.isActive ? true : false}
            aria-controls="tab-default-1"
            onClick={() => openTab("detail")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-orders">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#record"></use>
                </svg>
              </span>
            </span>
            {tabs.detail.label}
          </a>
        </li>
        <li
          title="Client"
          role="presentation"
          className={`slds-tabs_default__item ${
            tabs.client.isActive ? "slds-is-active" : ""
          }`}
        >
          <a
            className="slds-tabs_default__link"
            href="#client"
            role="tab"
            tabIndex="1"
            aria-selected={tabs.client.isActive ? true : false}
            aria-controls="tab-default-2"
            onClick={() => openTab("client")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-account">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#person_account"></use>
                </svg>
              </span>
            </span>
            {tabs.client.label}
          </a>
        </li>
      </ul>
      <div
        id="tab-default-1"
        className={`slds-tabs_default__content ${
          tabs.detail.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-1__item"
      >
        {props.order._id && <OrderRecordDetail order={props.order} />}
      </div>
      <div
        id="tab-default-2"
        className={`slds-tabs_default__content ${
          tabs.client.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-2__item"
      >
        {props.order.accountId && tabs.client.isActive && (
          <AccountDetail
            accountId={props.order.accountId}
            account={account}
            setAccount={setAccount}
          />
        )}
      </div>
    </div>
  );
};

export default RecordTab;
