import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { createOrder } from "../../api/ordersAPI";

import { Datepicker } from "../shared/Datepicker";
import { Loader } from "../shared/Loader";

import { getDefaultOrderState } from "../../utils/dataModelUtils";
import { getOrderDetailURL } from "../../utils/navigationUtils";

export const Create = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const dueDate = new Date(); // gets the current date
  dueDate.setDate(dueDate.getDate() + 30); // adds 30 days

  const [orderState, setOrderState] = useState(() => {
    const initialState = getDefaultOrderState();
    initialState.orderDueDate = dueDate;
    initialState.accountId = props.account._id;
    initialState.accountAddress = props.account.address;
    initialState.accountLastName = props.account.lastName;
    initialState.accountFirstName = props.account.firstName;
    initialState.accountCompanyName = props.account.companyName;
    
    return initialState;
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (isButtonDisabled) {
      return;
    } else {
      setIsButtonDisabled(true);
    }
    try {
      const token = await getAccessTokenSilently();
      const order_id = await createOrder(orderState, token);
      navigate(getOrderDetailURL(order_id));
    } catch (e) {
      console.error(e);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  const setOrderDate = (date) => {
    setOrderState((prevState) => ({
      ...prevState,
      orderDate: date,
    }));
  };

  const setOrderDueDate = (date) => {
    setOrderState((prevState) => ({
      ...prevState,
      orderDueDate: date,
    }));
  };

  return (
    <div>
      {isButtonDisabled && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_x-small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler la création et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Nouvelle Commande
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <section>
              <div className="slds-size_1-of-2 slds-p-left_x-small">
                <Datepicker label="Date d'émission" onSet={setOrderDate} />
              </div>
            </section>
            <section>
              <div className="slds-size_1-of-2 slds-p-left_x-small">
                <Datepicker
                  label="Date d'échéance"
                  onSet={setOrderDueDate}
                  selectedValue={orderState.orderDueDate}
                />
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField1Label"
                  >
                    Label en-tête #1
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField1Label"
                      id="text-input-invoiceHeaderField1Label"
                      className="slds-input"
                      placeholder="ex: Référence interne"
                      value={orderState.invoiceHeaderField1Label}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField1Value"
                  >
                    Valeur en-tête #1
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField1Value"
                      id="text-input-invoiceHeaderField1Value"
                      className="slds-input"
                      placeholder="ex: AEZ-3823-BD"
                      value={orderState.invoiceHeaderField1Value}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField2Label"
                  >
                    Label en-tête #2
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField2Label"
                      id="text-input-invoiceHeaderField2Label"
                      className="slds-input"
                      placeholder="ex: Numéro client"
                      value={orderState.invoiceHeaderField2Label}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField2Value"
                  >
                    Valeur en-tête #2
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField2Value"
                      id="text-input-invoiceHeaderField2Value"
                      className="slds-input"
                      placeholder="ex: CUST2424490"
                      value={orderState.invoiceHeaderField2Value}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField3Label"
                  >
                    Label en-tête #3
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField3Label"
                      id="text-input-invoiceHeaderField3Label"
                      className="slds-input"
                      placeholder="ex: IBAN"
                      value={orderState.invoiceHeaderField3Label}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-invoiceHeaderField3Value"
                  >
                    Valeur en-tête #3
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="invoiceHeaderField3Value"
                      id="text-input-invoiceHeaderField3Value"
                      className="slds-input"
                      placeholder="FR760854750842702872804"
                      value={orderState.invoiceHeaderField3Value}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-footer"
                  >
                    Pied de page
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="footer"
                      id="text-input-footer"
                      className="slds-input"
                      placeholder="Merci de votre commande !"
                      value={orderState.footer}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="slds-size_3-of-3 slds-p-left_x-small">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="textarea-id-specialInstructions"
                  >
                    Demande Spéciale
                  </label>
                  <div className="slds-form-element__control">
                    <textarea
                      id="textarea-id-specialInstructions"
                      className="slds-textarea"
                      rows="6"
                      cols="50"
                      maxLength="255"
                      name="specialInstructions"
                      value={orderState.specialInstructions}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={handleClose}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#back"></use>
              </svg>
              Annuler
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleSave}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#save"></use>
              </svg>
              Enregistrer
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default Create;
