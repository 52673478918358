import React from "react";
import { useNavigate } from "react-router-dom";

import Actions from "../order/RecordPageHeaderActions";

import { formatDate } from "../../utils/dateUtils";
import { getLabel } from "../../utils/dataModelUtils";
import { getAccountDetailURL } from "../../utils/navigationUtils";

export const PageHeader = (props) => {
  const navigate = useNavigate();
  const { accountFirstName, accountLastName, accountCompanyName } = props.order;
  const formattedName = `${
    accountFirstName ? accountFirstName + " " : ""
  }${accountLastName}${
    accountCompanyName ? " (" + accountCompanyName + ")" : ""
  }`;

  return (
    <div className="slds-page-header slds-page-header_record-home slds-m-left_xx-small slds-m-right_xx-small">
      <div className="slds-page-header__row">
        <div className="slds-page-header__col-title">
          <div className="slds-media">
            <div className="slds-media__figure">
              <span className="slds-icon_container slds-icon-standard-orders">
                <svg
                  className="slds-icon slds-page-header__icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#orders"></use>
                </svg>
              </span>
            </div>
            <div className="slds-media__body">
              <div className="slds-page-header__name">
                <div className="slds-page-header__name-title">
                  <h1>
                    <span>Commandes</span>
                    <span className="slds-page-header__title slds-cell-wrap">
                      {props.order.orderReference}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Actions order={props.order} refetchData={props.refetchData} />
      </div>
      <div className="slds-page-header__row slds-page-header__row_gutters">
        <div className="slds-page-header__col-details">
          <ul className="slds-page-header__detail-row slds-show_medium">
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap">Client</div>
              <div className="slds-cell-wrap">
                <a
                  href="#client"
                  onClick={() => {
                    navigate(getAccountDetailURL(props.order.accountId));
                  }}
                >
                  {formattedName}
                </a>
              </div>
            </li>
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap">Statut</div>
              <div className="slds-cell-wrap">
                {getLabel("orderStatus", props.order.orderStatus)}
              </div>
            </li>
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap">Date d'émission</div>
              <div className="slds-cell-wrap">
                {formatDate(props.order.orderDate)}
              </div>
            </li>
            <li className="slds-page-header__detail-block">
              <div className="slds-text-title slds-cell-wrap">Total TTC</div>
              <div className="slds-cell-wrap">
                {parseFloat(props.order.totalAmountWithTax).toFixed(2)}€
              </div>
            </li>
          </ul>
          <ul className="slds-page-header__detail-list slds-hide_medium">
            <li className="slds-page-header__detail-item">
              <div className="slds-text-title slds-truncate" title="Client">
                Client
              </div>
              <div className="slds-truncate" title="Client">
                <a
                  href="#client"
                  onClick={() => {
                    navigate(getAccountDetailURL(props.order.accountId));
                  }}
                >
                  {formattedName}
                </a>
              </div>
            </li>
            <li className="slds-page-header__detail-item">
              <div className="slds-text-title slds-truncate" title="Statut">
                Statut
              </div>
              <div title={getLabel("orderStatus", props.order.orderStatus)}>
                {getLabel("orderStatus", props.order.orderStatus)}
              </div>
            </li>
            <li className="slds-page-header__detail-item">
              <div className="slds-text-title slds-truncate" title="Date">
                Date
              </div>
              <div title={formatDate(props.order.orderDate)}>
                {formatDate(props.order.orderDate)}
              </div>
            </li>
            <li className="slds-page-header__detail-item">
              <div className="slds-text-title slds-truncate" title="Total TTC">
                Total TTC
              </div>
              <div
                title={
                  parseFloat(props.order.totalAmountWithTax).toFixed(2) + "€"
                }
              >
                {parseFloat(props.order.totalAmountWithTax).toFixed(2)}€
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
