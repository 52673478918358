import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import { createAccount } from "../../api/accountsAPI";

import Autocomplete from "../shared/Autocomplete";
import { Loader } from "../shared/Loader";

import { getDefaultAccountState } from "../../utils/dataModelUtils";
import { getAccountDetailURL } from "../../utils/navigationUtils";

export const Create = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const defaultAccountState = getDefaultAccountState();

  const [accountState, setAccountState] = useState(defaultAccountState);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (!accountState.lastName) {
      console.log("Missing required fields.");
      setLastNameError(true);
      return;
    } else {
      setLastNameError(false);
    }
    if (isButtonDisabled) {
      return;
    } else {
      setIsButtonDisabled(true);
    }
    try {
      const token = await getAccessTokenSilently();
      const account_id = await createAccount(accountState, token);
      navigate(getAccountDetailURL(account_id));
    } catch (e) {
      console.error(e);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  const setAddress = (autocomplete) => {
    let mapping = {};
    let place = autocomplete.getPlace();
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      mapping[addressType] = place.address_components[i].long_name;
    }
    setAccountState((prevState) => ({
      ...prevState,
      address: {
        name: place.name,
        street: place.name,
        streetNumber: mapping.street_number,
        route: mapping.route,
        city: mapping.locality,
        zipcode: mapping.postal_code,
        country: mapping.country,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      },
    }));
  };

  return (
    <div>
      {isButtonDisabled && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler la création et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Nouveau Client
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-firstName"
                  >
                    Prénom
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="firstName"
                      id="text-input-firstName"
                      className="slds-input"
                      value={accountState.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="slds-col_padded">
                <div
                  className={`slds-form-element ${
                    lastNameError ? "slds-has-error" : ""
                  }`}
                >
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-lastName"
                  >
                    Nom*
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="lastName"
                      id="text-input-lastName"
                      className="slds-input"
                      value={accountState.lastName}
                      onChange={handleInputChange}
                    />
                  </div>
                  {lastNameError && (
                    <div
                      className="slds-form-element__help"
                      id="form-error-lastName"
                    >
                      Ce champs est obligatoire.
                    </div>
                  )}
                </div>
              </div>
            </section>
            <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-id-email"
                  >
                    Email
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="email"
                      id="text-input-id-email"
                      className="slds-input"
                      value={accountState.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-id-phone"
                  >
                    Téléphone
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="phone"
                      id="text-input-id-phone"
                      className="slds-input"
                      value={accountState.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-companyName"
                  >
                    Entreprise
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="companyName"
                      id="text-input-companyName"
                      className="slds-input"
                      value={accountState.companyName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-vat"
                  >
                    Numéro de TVA
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="vat"
                      id="text-input-vat"
                      className="slds-input"
                      value={accountState.vat}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-website"
                  >
                    Site web
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="website"
                      id="text-input-website"
                      className="slds-input"
                      value={accountState.website}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="slds-col_padded">
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="text-input-fax"
                  >
                    Fax
                  </label>
                  <div className="slds-form-element__control">
                    <input
                      type="text"
                      name="fax"
                      id="text-input-fax"
                      className="slds-input"
                      value={accountState.fax}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <Autocomplete
                setAddress={setAddress}
                name="address"
                label="Adresse du client"
              />
            </section>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={handleClose}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#back"></use>
              </svg>
              Annuler
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleSave}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#save"></use>
              </svg>
              Enregistrer
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default Create;
