import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { getOrderLines } from "../../api/ordersAPI";

import EmptyState from "../shared/EmptyState";
import RelatedOrderLinesMiseADisposition from "./RelatedOrderLinesMiseADisposition";
import RelatedOrderLinesTransfert from "./RelatedOrderLinesTransfert";
import OrderRecordDetail from "../order/RecordDetail";
import AccountDetail from "../order/RecordAccountDetail";

export const RecordRelatedTabs = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const [tabs, setTabs] = useState({
    detail: {
      label: "Détail",
      isActive: false,
    },
    client: {
      label: "Client",
      isActive: false,
    },
    orderlines: {
      label: "Courses",
      isActive: true,
    },
  });

  const openTab = (tabName) => {
    const updatedTabs = { ...tabs };
    for (const tab in updatedTabs) {
      updatedTabs[tab].isActive = tab === tabName;
    }
    setTabs(updatedTabs);
  };

  const [orderLinesMiseADisposition, setOrderLinesMiseADisposition] =
    useState(null);
  const [orderLinesTransfert, setOrderLinesTransfert] = useState(null);
  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const orderLines = await getOrderLines(props.order._id,token);
      if (orderLines === null) {
        setOrderLinesMiseADisposition([]);
        setOrderLinesTransfert([]);
      } else {
        let miseADispositions = [];
        let transferts = [];
        orderLines.forEach((orderLine) => {
          if (orderLine.type === "mise_a_disposition") {
            miseADispositions.push(orderLine);
          }
          if (orderLine.type === "transfert") {
            transferts.push(orderLine);
          }
        });
        setOrderLinesMiseADisposition(miseADispositions);
        setOrderLinesTransfert(transferts);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [account, setAccount] = useState(null);

  const refresh = () => {
    fetchData();
    props.refetchData && props.refetchData();
  };

  return (
    <div className="slds-tabs_medium slds-tabs-mobile slds-var-p-around_small">
      <ul className="slds-tabs_default__nav" role="tablist">
        <li
          className={`slds-tabs_default__item ${
            tabs.orderlines.isActive ? "slds-is-active" : ""
          }`}
          title="Order Lines"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#orderlines"
            role="tab"
            tabIndex="0"
            aria-selected={tabs.orderlines.isActive ? true : false}
            aria-controls="tab-default-1"
            onClick={() => openTab("orderlines")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-work-order">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#work_order"></use>
                </svg>
              </span>
            </span>
            Courses
          </a>
        </li>
        <li
          className={`slds-tabs_default__item slds-hide_medium ${
            tabs.detail.isActive ? "slds-is-active" : ""
          }`}
          title="Détail"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#detail"
            role="tab"
            tabIndex="1"
            aria-selected={tabs.detail.isActive ? true : false}
            aria-controls="tab-default-2"
            onClick={() => openTab("detail")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-orders">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#record"></use>
                </svg>
              </span>
            </span>
            Détail
          </a>
        </li>
        <li
          className={`slds-tabs_default__item slds-hide_medium ${
            tabs.client.isActive ? "slds-is-active" : ""
          }`}
          title="Client"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#client"
            role="tab"
            tabIndex="2"
            aria-selected={tabs.client.isActive ? true : false}
            aria-controls="tab-default-3"
            onClick={() => openTab("client")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-account">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#person_account"></use>
                </svg>
              </span>
            </span>
            Client
          </a>
        </li>
      </ul>
      <div
        id="tab-default-1"
        className={`slds-tabs_default__content ${
          tabs.orderlines.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-1__item"
      >
        {orderLinesTransfert !== null && (
          <RelatedOrderLinesTransfert
            order={props.order}
            refetchData={refresh}
            orderlines={orderLinesTransfert}
          />
        )}
        {orderLinesMiseADisposition !== null && (
          <RelatedOrderLinesMiseADisposition
            order={props.order}
            refetchData={refresh}
            orderlines={orderLinesMiseADisposition}
          />
        )}
        {orderLinesTransfert !== null && orderLinesMiseADisposition !== null && orderLinesTransfert.length === 0 &&
          orderLinesMiseADisposition.length === 0 && (
            <article className="slds-card">
              <div className="slds-card__body">
                <EmptyState
                  heading={"Ajoutez une course à votre commande"}
                />
              </div>
            </article>
          )}
      </div>
      <div
        id="tab-default-2"
        className={`slds-tabs_default__content ${
          tabs.detail.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-2__item"
      >
        {props.order._id && <OrderRecordDetail order={props.order} />}
      </div>
      <div
        id="tab-default-3"
        className={`slds-tabs_default__content ${
          tabs.client.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-3__item"
      >
        {props.order.accountId && tabs.client.isActive && (
          <AccountDetail
            accountId={props.order.accountId}
            account={account}
            setAccount={setAccount}
          />
        )}
      </div>
    </div>
  );
};

export default RecordRelatedTabs;