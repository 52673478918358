export function prettifyName(account) {
  if (account.firstName && account.lastName) {
    return `${account.firstName} ${account.lastName}`;
  }
  return account.lastName || "";
}

export function formatAddress(address) {
  const formatted = [
    address.street,
    address.zipcode && address.city
      ? `${address.zipcode} ${address.city}`
      : address.zipcode || address.city,
    address.country,
  ]
    .filter(Boolean)
    .join(", ");
  return formatted;
}

export function formatAddressWeather(address) {
  const formatted = [
    address.zipcode && address.city
      ? `${address.zipcode} ${address.city}`
      : address.zipcode || address.city,
    address.country,
  ]
    .filter(Boolean)
    .join(", ");
  return formatted;
}

export function formatOrderLineType(type) {
  let typeMap = {
    mise_a_disposition: "Mise à disposition",
    transfert: "Transfert",
  };
  return typeMap[type];
}

export function formatFromAddressOrderLine(orderline) {
  const formatted = [
    orderline.fromAddressName || orderline.fromAddressStreetNumber + ' ' + orderline.fromAddressRoute,
    orderline.fromAddressZipcode && orderline.fromAddressCity
      ? `${orderline.fromAddressZipcode} ${orderline.fromAddressCity}`
      : orderline.fromAddressZipcode || orderline.fromAddressCity,
    orderline.fromAddressCountry,
  ]
    .filter(Boolean)
    .join(", ");
  return formatted;
}

export function formatToAddressOrderLine(orderline) {
  const formatted = [
    orderline.toAddressName || orderline.toAddressStreetNumber + ' ' + orderline.toAddressRoute,
    orderline.toAddressZipcode && orderline.toAddressCity
      ? `${orderline.toAddressZipcode} ${orderline.toAddressCity}`
      : orderline.toAddressZipcode || orderline.toAddressCity,
    orderline.toAddressCountry,
  ]
    .filter(Boolean)
    .join(", ");
  return formatted;
}