const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const loadGoogleMaps = () => {
  if (window.google) return; // If already loaded, do nothing
  if (document.getElementById("google-script")) return; // If loading in progress, do nothing

  const script = document.createElement("script");
  script.id = "google-script";
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${API_KEY}`;
  script.onload = () => {
    window.dispatchEvent(new Event("googleMapsLoaded"));
  };
  document.body.appendChild(script);
};