import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from 'react-helmet';
import { getAllAccounts } from "../api/accountsAPI";

import ListPageHeader from "../components/account/ListPageHeader.js";
import DataTable from "../components/account/DataTable.js";
import EmptyState from "../components/shared/EmptyState";

export const Accounts = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [accounts, setAccounts] = useState(null);
  const [listViewId, setListViewId] = useState("default");

  const refreshData = (newListViewId) => {
    setListViewId(newListViewId);
  };

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = await getAllAccounts(listViewId, token);
        setAccounts(data);
      } catch (e) {
        setAccounts([]);
        console.error(e);
      }
    })();
  }, [listViewId]);

  return (
    <div>
      <Helmet>
        <title>{`Kipzco - Clients`}</title>
      </Helmet>
      {accounts && (
        <ListPageHeader accounts={accounts} onSelectListView={refreshData} />
      )}
      {accounts && accounts.length > 0 && <DataTable accounts={accounts} />}
      {accounts && accounts.length === 0 && (
        <article
          className="slds-card"
          style={{
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
        >
          <div className="slds-card__body">
            <EmptyState heading={"Aucun client à afficher."} body="Créez un nouveau client ou utilisez une vue différente."/>
          </div>
        </article>
      )}
    </div>
  );
};

export default Accounts;
