import React, { useState } from "react";

export const ListPageHeader = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const viewObjects = [
    { id: 1, name: "Mes commandes", listViewId: "default" },
    { id: 2, name: "Mes favoris", listViewId: "myFavoritOrders" },
    { id: 3, name: "Mes Brouillons", listViewId: "draft" },
    { id: 4, name: "Commandes en cours", listViewId: "open" },
    { id: 5, name: "Commandes annulées", listViewId: "void" },
    { id: 6, name: "Commandes payées", listViewId: "paid" },
    { id: 7, name: "Commandes non-recouvrables", listViewId: "uncollectible" },
  ];
  const [selectedView, setSelectedView] = useState(viewObjects[0]);

  const handleViewChange = (event, view) => {
    event.preventDefault();
    setSelectedView(view);
    setIsDropdownOpen(false);
    props.onSelectListView(view.listViewId);
  };

  const switchDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div
      className="slds-page-header"
      style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
    >
      <div className="slds-page-header__row">
        <div className="slds-page-header__col-title">
          <div className="slds-media">
            <div className="slds-media__figure">
              <span className="slds-icon_container slds-icon-standard-orders">
                <svg
                  className="slds-icon slds-page-header__icon"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#orders"></use>
                </svg>
              </span>
            </div>
            <div className="slds-media__body">
              <div className="slds-page-header__name">
                <div className="slds-page-header__name-title">
                  <h1>
                    <span>Commandes</span>
                    <span
                      className="slds-page-header__title slds-truncate"
                      title={selectedView.name}
                      onClick={switchDropdown}
                    >
                      {selectedView.name}
                    </span>
                  </h1>
                </div>
                <div className="slds-page-header__name-switcher">
                  <div
                    className={`slds-dropdown-trigger slds-dropdown-trigger_click${
                      isDropdownOpen ? " slds-is-open" : ""
                    }`}
                  >
                    <button
                      className="slds-button slds-button_icon slds-button_icon-small"
                      aria-haspopup="true"
                      title="Switch list view"
                      onClick={switchDropdown}
                    >
                      <svg
                        className="slds-button__icon slds-icon_x-small"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#down"></use>
                      </svg>
                      <span className="slds-assistive-text">Switch list view</span>
                    </button>
                    <div className="slds-dropdown slds-dropdown_center slds-dropdown_small">
                      <ul
                        className="slds-dropdown__list"
                        role="menu"
                        aria-label="Select View"
                      >
                        {viewObjects.map((view) => (
                          <li
                            key={view.id}
                            className="slds-dropdown__item"
                            role="presentation"
                          >
                            <a
                              href="#d"
                              role="menuitem"
                              tabIndex="0"
                              onClick={(e) => handleViewChange(e, view)}
                            >
                              <span className="slds-truncate" title={view.name}>
                                {view.name}
                              </span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slds-page-header__row">
        <div className="slds-page-header__col-meta">
          <p className="slds-page-header__meta-text">
            {props.orders.length} éléments
          </p>
        </div>
      </div>
    </div>
  );
};

export default ListPageHeader;
