import React from "react";

import { getLabel } from "../../utils/dataModelUtils";
import { formatDatetime, formatDate } from "../../utils/dateUtils";

export const Detail = (props) => {
  return (
    <div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Information Générale
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-orderDate"
                >
                  Date d'émission
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-orderDate"
                    readOnly={true}
                    className="slds-input"
                    value={formatDate(props.order.orderDate)}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-orderStatus"
                >
                  Date d'échéance
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-orderDate"
                    readOnly={true}
                    className="slds-input"
                    value={formatDate(props.order.orderDueDate)}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-orderStatus"
                >
                  Statut
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-orderStatus"
                    className="slds-input"
                    value={getLabel("orderStatus", props.order.orderStatus)}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Finances et Paiement
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalAmountWithoutTax"
                >
                  Montant HT
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-totalAmountWithoutTax"
                    readOnly={true}
                    className="slds-input"
                    value={`${parseFloat(
                      props.order.totalAmountWithoutTax
                    ).toFixed(2)}€`}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalTax"
                >
                  Montant TVA
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-totalTax"
                    readOnly={true}
                    className="slds-input"
                    value={`${parseFloat(props.order.totalTax).toFixed(2)}€`}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalAmountWithTax"
                >
                  Montant TTC
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    id="text-input-totalAmountWithTax"
                    readOnly={true}
                    className="slds-input"
                    value={`${parseFloat(
                      props.order.totalAmountWithTax
                    ).toFixed(2)}€`}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded"></div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Facture
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalAmountWithoutTax"
                >
                  Label en-tête #1
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="invoiceHeaderField1Label"
                    id="text-input-invoiceHeaderField1Label"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.invoiceHeaderField1Label}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalTax"
                >
                  Valeur en-tête #1
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="invoiceHeaderField1Value"
                    id="text-input-invoiceHeaderField1Value"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.invoiceHeaderField1Value}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalAmountWithoutTax"
                >
                  Label en-tête #2
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="invoiceHeaderField2Label"
                    id="text-input-invoiceHeaderField2Label"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.invoiceHeaderField2Label}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalTax"
                >
                  Valeur en-tête #2
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="invoiceHeaderField2Value"
                    id="text-input-invoiceHeaderField2Value"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.invoiceHeaderField2Value}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalAmountWithoutTax"
                >
                  Label en-tête #3
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="invoiceHeaderField3Label"
                    id="text-input-invoiceHeaderField3Label"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.invoiceHeaderField3Label}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalTax"
                >
                  Valeur en-tête #3
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="invoiceHeaderField3Value"
                    id="text-input-invoiceHeaderField3Value"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.invoiceHeaderField3Value}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-totalAmountWithoutTax"
                >
                  Pied de page
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    name="footer"
                    id="text-input-footer"
                    readOnly={true}
                    className="slds-input"
                    value={props.order.footer}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Information complémentaire
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <span className="slds-form-element__label">
                  Demande spéciale
                </span>
                <div className="slds-form-element__control slds-border_bottom">
                  <div className="slds-form-element__static">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: props.order.specialInstructions.replace(
                          /\n/g,
                          "<br/>"
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="slds-section slds-is-open">
        <h3 className="slds-section__title slds-theme_shade">
          <span
            className="slds-truncate slds-p-horizontal_small"
            title="Section Title"
          >
            Système
          </span>
        </h3>
        <div
          aria-hidden="false"
          className="slds-section__content slds-var-p-around_x-small"
        >
          <section className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-createdDate"
                >
                  Date de création
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-createdDate"
                    className="slds-input"
                    value={formatDatetime(props.order.createdDate)}
                  />
                </div>
              </div>
            </div>
            <div className="slds-col_padded">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-input-lastModifiedDate"
                >
                  Dernière modification
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="text"
                    readOnly={true}
                    id="text-input-lastModifiedDate"
                    className="slds-input"
                    value={formatDatetime(props.order.lastModifiedDate)}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Detail;
