import React from "react";
import { useNavigate } from "react-router-dom";
import { getOrderDetailURL } from "../../utils/navigationUtils";

import { formatDatetime } from "../../utils/dateUtils";
import { formatFromAddressOrderLine } from "../../utils/stringUtils";

export const OrderLineResults = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="slds-page-header"
        style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media">
              <div className="slds-media__figure">
                <span
                  className="slds-icon_container slds-icon-standard-work-order"
                  title="course"
                >
                  <svg
                    className="slds-icon slds-page-header__icon"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#work_order"></use>
                  </svg>
                  <span className="slds-assistive-text">course</span>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span
                        className="slds-page-header__title slds-truncate"
                        title="Rohde Corp - 80,000 Widgets"
                      >
                        Courses
                      </span>
                    </h1>
                  </div>
                </div>
                <p className="slds-page-header__name-meta">
                  {`${props.orderLines.length} résultat(s)`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table
        className="slds-table slds-no-row-hover slds-table_bordered slds-table_fixed-layout slds-max-medium-table_stacked"
        role="grid"
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="slds-cell-wrap" scope="col">
              <div title="Commande">Commande</div>
            </th>
            <th className="slds-cell-wrap" scope="col">
              <div title="Départ">Départ</div>
            </th>
            <th className="slds-cell-wrap" scope="col">
              <div title="Date">Date/Heure</div>
            </th>
            <th className="slds-cell-wrap" scope="col">
              <div title="Montant TTC">Montant TTC</div>
            </th>
            <th className="slds-cell-wrap" scope="col">
              <div title="Statut">Description</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.orderLines.map((orderLine) => (
            <tr className="slds-hint-parent" key={orderLine._id}>
              <th data-label="Commande" scope="row" className="slds-cell-wrap">
                <div title={formatFromAddressOrderLine(orderLine)}>
                  <a
                    href="#view"
                    tabIndex="-1"
                    onClick={() => navigate(getOrderDetailURL(orderLine.orderId))}
                  >
                    {orderLine.orderId}
                  </a>
                </div>
              </th>
              <td data-label="Départ" className="slds-cell-wrap">
                <div title={formatFromAddressOrderLine(orderLine)}>
                  {formatFromAddressOrderLine(orderLine)}
                </div>
              </td>
              <td data-label="Date/Heure" className="slds-cell-wrap">
                <div title={formatDatetime(orderLine.startDate)}>
                  {formatDatetime(orderLine.startDate)}
                </div>
              </td>
              <td data-label="Montant TTC" className="slds-cell-wrap">
                <div
                  title={parseFloat(orderLine.totalAmountWithTax).toFixed(2)}
                >
                  {parseFloat(orderLine.totalAmountWithTax).toFixed(2)}€
                </div>
              </td>
              <td data-label="Description" className="slds-cell-wrap">
                <div title={orderLine.description} className="slds-cell-wrap">
                  {orderLine.description}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderLineResults;
