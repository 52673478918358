import React from "react";

import VerticalNavigation from "./global/VerticalNavigation";

const VerticalHeaderContainer = () => {
  return (
    <header className="slds-global-header_container slds-hide_medium">
      <VerticalNavigation />
    </header>
  );
};

export default VerticalHeaderContainer;