import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";

import { getAccount } from "../api/accountsAPI";
import { getAccountListURL } from "../utils/navigationUtils";
import { prettifyName } from "../utils/stringUtils";

import PageHeader from "../components/account/RecordPageHeader.js";
import RecordTabs from "../components/account/RecordTabs.js";
import RecordRelatedTabs from "../components/account/RecordRelatedTabs.js";

export const AccountDetail = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { id } = useParams();
  const [account, setAccount] = useState({});

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const data = await getAccount(id, token);
      if (data === null) {
        navigate(getAccountListURL());
      } else {
        setAccount(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div>
      {account._id && (
        <Helmet>
          <title>{`Kipzco - ${prettifyName(account)}`}</title>
        </Helmet>
      )}
      {account._id && <PageHeader account={account} refetchData={fetchData} />}
      <div className="slds-grid slds-wrap slds-gutters_x-small slds-var-p-around_x-small">
        <div className="slds-col slds-order_2 slds-medium-order_1 slds-large-order_1 slds-size_1-of-1 slds-medium-size_4-of-12 slds-large-size_4-of-12">
          {account._id && <RecordTabs account={account} />}
        </div>
        <div className="slds-col slds-order_1 slds-medium-order_2 slds-large-order_2 slds-size_1-of-1 slds-medium-size_8-of-12 slds-large-size_8-of-12">
          {account._id && <RecordRelatedTabs account={account} />}
        </div>
      </div>
    </div>
  );
};

export default AccountDetail;
