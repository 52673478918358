import React from "react";
import { Helmet } from 'react-helmet';

import Header from "../components/support/Header"
import Articles from "../components/support/Articles"

export const Support = () => {
  return (
    <div>
      <Helmet>
        <title>{`Kipzco - Aide`}</title>
      </Helmet>
      <Header />
      <Articles />
    </div>
  );
};

export default Support;