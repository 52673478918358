import React from "react";
import { Helmet } from 'react-helmet';

import ComingTrips from "../components/home/ComingTrips";
import OpenOrders from "../components/home/OpenOrders";
import RecentAccounts from "../components/home/RecentAccounts";

export const Home = () => {
  return (
    <div>
      <Helmet>
        <title>{`Kipzco - Page d'accueil`}</title>
      </Helmet>
      <div class="slds-page-header slds-m-bottom_x-small">
        <div class="slds-page-header__row">
          <div class="slds-page-header__col-title">
            <div class="slds-media">
              <div class="slds-media__figure">
                <span
                  class="slds-icon_container slds-icon-custom-custom107"
                  title="home"
                >
                  <svg
                    class="slds-icon slds-page-header__icon"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#home"></use>
                  </svg>
                  <span class="slds-assistive-text">home</span>
                </span>
              </div>
              <div class="slds-media__body">
                <div class="slds-page-header__name">
                  <div class="slds-page-header__name-title">
                    <h1>
                      <span
                        class="slds-page-header__title slds-truncate"
                        title="Accueil"
                      >
                        Accueil
                      </span>
                    </h1>
                  </div>
                </div>
                <p class="slds-page-header__name-meta">
                  {`Retrouvez vos courses, commandes et clients`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ComingTrips />
      <OpenOrders />
      <RecentAccounts />
    </div>
  );
};

export default Home;
