import React, { useEffect } from "react";

import { loadGoogleMaps } from "./googleMapsLoader";

const Autocomplete = (props) => {
  useEffect(() => {
    if (window.google) {
      initGoogleMap(); // If Google Maps is already loaded
    } else {
      // Initialize on custom event
      const googleMapsLoadedHandler = () => initGoogleMap();
      window.addEventListener("googleMapsLoaded", googleMapsLoadedHandler);

      // Initiate the loading of Google Maps script
      loadGoogleMaps();

      return () => {
        window.removeEventListener("googleMapsLoaded", googleMapsLoadedHandler);
      };
    }
  }, [props]);

  const initGoogleMap = () => {
    const google = window.google;

    const options = {
      componentRestrictions: { country: ["fr", "ch", "es", "it", "be", "de"] },
      fields: ["address_components", "geometry", "name", "formatted_address"],
    };

    const addressDepart = document.getElementById(props.name);
    const autocomplete_from = new google.maps.places.Autocomplete(
      addressDepart,
      options
    );

    const handlePlaceChange = () => {
      props.setAddress(autocomplete_from);
    };
    autocomplete_from.addListener("place_changed", handlePlaceChange);

    const handleChange = () => {
      addressDepart.value = null;
    };
    addressDepart.addEventListener("change", handleChange);

    return () => {
      google.maps.event.clearListeners(autocomplete_from, "place_changed");
      addressDepart.removeEventListener("change", handleChange);
    };
  };

  return (
    <div className="slds-form-element">
      <label className="slds-form-element__label" htmlFor={props.name}>
        {props.label}
      </label>
      <div className="slds-form-element__control">
        <input
          type="text"
          className="form-control pac-target-input slds-input slds-m-top_xx-small"
          id={props.name}
          name={props.name}
          defaultValue={props.selectedValue}
          placeholder={props.placeholder}
          required
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default Autocomplete;
