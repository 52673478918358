import React, { useEffect, useState } from "react";

import AccountDataTable from "../account/DataTable";

export const AccountResults = (props) => {
  return (
    <div className="slds-m-bottom_x-small">
      <div
        className="slds-page-header"
        style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media">
              <div className="slds-media__figure">
                <span
                  className="slds-icon_container slds-icon-standard-account"
                  title="client"
                >
                  <svg
                    className="slds-icon slds-page-header__icon"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#person_account"></use>
                  </svg>
                  <span className="slds-assistive-text">client</span>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span
                        className="slds-page-header__title slds-truncate"
                        title="Rohde Corp - 80,000 Widgets"
                      >
                        Clients
                      </span>
                    </h1>
                  </div>
                </div>
                <p className="slds-page-header__name-meta">
                  {`${props.accounts.length} résultat(s)`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccountDataTable accounts={props.accounts} />
    </div>
  );
};

export default AccountResults;
